#order-component-wrapper{
    #order-nav {
        display: flex;
        font-size: 1rem;
        // patch for aligning nav
        >.order-nav-item {
            // padding: 0;
            align-items: center;
            justify-content: center;
            border: none;
            >div, >a {
            // padding: $gap;
            transition: background-color .3s ease;
            &:hover { background-color: rgba($color: #000000, $alpha: .025); }
            }
            a:hover { text-decoration: none; }
            .order-nav-item-inner {
                display: flex;
                align-items: center;
                justify-content: center;
                .order-nav-item-index {
                    min-width: 2.5em;
                    >span {
                        // font-size: 2em;
                        font-family: $font-family-bold;
                        color: $color-brand;
                    }
                }
                .order-nav-item-title {
                    display: flex;
                    flex-direction: column;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    align-items: flex-start;
                    >span{
                        &:first-child{
                            padding-bottom: $m1;
                        }
                    }
                }
            }
            &.active{
                background-color: rgba($color: #000000, $alpha: .05);
                .order-nav-item-title{
                    font-family: $font-family-bold;
                    >span{
                        color: $font-color;
                    }
                }
            }
            &.inactive{
                cursor: default;
                .order-nav-item-title{
                    >span{
                        color: $grey;
                    }
                }
                .order-nav-item-index{
                    >span { color: $grey-light; }
                }
            }
        }
        .order-nav-spacer-wrapper {
                align-items: center;
                // margin: 0 $gap;
                display: flex;
            >.order-nav-spacer {
                display: flex;
                align-items: center;
                // font-size: 5em;
            }

        }
    }
}
#shopping-cart-positions-wrapper{
    #shopping-cart-positions-header{
        margin: 0;
        width: 100%;
        height: $gap;
        padding: 0 $gap-sm;
        background-color: rgba(0,0,0,.02);
        background: linear-gradient(180deg, rgba(0,0,0,0.025) 0%, rgba(255,255,255,0) 75%);
        >span{
            display: flex;
            align-items: center;
            font-family: $font-family;
            font-weight: bold;
            color: $font-color;
            height: 100%;
        }
    }
    .shopping-cart-group-position{
        margin-bottom: $gap;
        .shopping-cart-group-position-header {
            >div{
                display: flex;
                align-items: center;
                &.cart-pos-sum-wrapper{
                    justify-content: end;
                }
            }
            >.h2{
                letter-spacing: normal;
                margin-bottom: 0;
                >span{
                    padding-left: $gap;
                }
            }
            .btn-collapse-toggle{
                display: flex;
                justify-content: space-between;
                align-items: center;
                background-color: transparent;
                width: 100%;
                border:none;
                padding: 0;
                transform: scale(2);
                >svg{
                    transition: transform .3s ease;
                }
            }
            input {
                padding: .5rem; //var?
            }
        }
        .shopping-cart-group-position-main {
            >.MuiCollapse-root{
                >.MuiCollapse-wrapper{
                    transition: border .3s ease;
                    margin-top:$gap;
                }
            }
            .shopping-cart-position{
                .shopping-cart-position-wrapper {
                    padding-left: 0;
                    padding-right: 0;
                }
                &:last-child {
                    .shopping-cart-position-wrapper {
                        margin-bottom: 0;
                        padding-bottom: 0;
                    }
                }
            }
        }
        &.active{
            .shopping-cart-group-position-main {
                >.MuiCollapse-root{
                    >.MuiCollapse-wrapper{
                        border-top: 1px solid $color-brand;
                    }
                }
            }
        }
    }
    .shopping-cart-position {
        &.position-type-Unknown {
            .shopping-cart-position-wrapper {
                box-shadow: 5px 0 0 0px red inset;
            }
        }
        .shopping-cart-position-wrapper {
            background-color: $white;
            padding: $gap-sm;
            input {
                padding: .5rem;
            }
            .shopping-cart-pos-amount-wrapper {
                    display: flex;
                    align-items: center;
                .shopping-cart-pos-amount-input {
                    display: flex;
                    align-items: center;
                    background-color: $color-brand;
                    >span{
                        padding:0 $m1;
                        color: $white;
                    }
                }
                .shopping-cart-pos-list-amount-packaging-quantity {
                    color: $color-brand;
                }
            }
            .shopping-cart-pos-img-wrapper {
                >img {
                    max-width: 100%;
                    object-fit: contain;
                    min-height: 5em;
                }
            }
            >.shopping-cart-pos-info-wrapper {
                >.shopping-cart-pos-info-wrapper-additional {
                    display: flex;
                    flex-direction: column;
                    >span{
                        margin-top: .2rem;
                        &:first-child {
                            margin-top: .5rem;
                        }
                    }
                }
            }
            .cart-cad-checkbox-wrapper {
                margin-right: $gap-sm;
                margin-left: $m1;
                >.MuiFormControl-root {
                    margin:0 !important;
                }
            }
        }
    }
    &.shopping-cart-positions--summary {
        background-color: $white;
        padding: $gap-sm;
        #shopping-cart-positions-header {
            background: none;
            margin-bottom: $m1;
            .shopping-cart-col-amount {
                justify-content: end;
            }
        }
        #shopping-cart-positions {
            >.shopping-cart-position {
                .shopping-cart-position-wrapper {
                    border: 1px solid $border-color;
                    margin-bottom: $gap-sm;
                    input {
                        padding: .5rem;
                    }
                    .shopping-cart-pos-img-wrapper {
                        >img{
                            max-width: 100%;
                            object-fit: contain;
                        }
                    }
                }
                &:last-child{
                    .shopping-cart-position-wrapper {
                        margin-bottom: 0;
                    }
                }
            }
            >.shopping-cart-group-position {
                border: 1px solid $border-color;
                .shopping-cart-group-position-header{
                    display: flex;
                    justify-content: space-between;
                }
            }
        }
    }
}
#fav-list-positions-wrapper{
    background-color: #EFEFEF;
    #fav-list-positions-header{
        margin: 0;
        width: 100%;
        height: $gap;
        padding: 0 $gap-sm;
        background-color: #EFEFEF;
        //background: linear-gradient(180deg, rgba(0,0,0,0.025) 0%, rgba(255,255,255,0) 75%);
        >span{
            display: flex;
            align-items: center;
            font-family: $font-family;
            font-weight: bold;
            color: $font-color;
        }
    }
    .fav-list-group-position{
        margin-bottom: $gap;
        .fav-list-group-position-header{
            >div{
                display: flex;
                align-items: center;
                &.cart-pos-sum-wrapper{
                    justify-content: end;
                }
            }
            >.h2{
                letter-spacing: normal;
                margin-bottom: 0;
                >span{
                    padding-left: $gap;
                }
            }
            .btn-collapse-toggle{
                display: flex;
                justify-content: space-between;
                align-items: center;
                background-color: transparent;
                width: 100%;
                border:none;
                padding: 0;
                transform: scale(2);
                >svg{
                    transition: transform .3s ease;
                }
            }
            input {
                padding: .5rem; //var?
            }
        }
        .fav-list-group-position-main {
            >.MuiCollapse-root{
                >.MuiCollapse-wrapper{
                    transition: border .3s ease;
                    margin-top:$gap;
                }
            }
            .fav-list-position{
                .fav-list-position-wrapper {
                    padding-left: 0;
                    padding-right: 0;
                }
                &:last-child{
                    .fav.list-position-wrapper {
                        margin-bottom: 0;
                        padding-bottom: 0;
                    }
                }
            }
        }
        &.active{
            .fav-list-group-position-main{
                >.MuiCollapse-root{
                    >.MuiCollapse-wrapper{
                        border-top: 1px solid $color-brand;
                    }
                }
            }
        }
    }
    .shipping-address{
        height: 70px;
    }
    .fav-list-position {
        .fav-list-position-wrapper {
            background-color: $white;
            padding: $gap-sm;
            margin: 0 0 $gap;
            input {
                padding: .5rem; //var?
            }
            align-items: center;
            justify-content:center;
        }
    }

        #fav-list-positions{
            >.fav-list-position {
                .fav-list-position-wrapper {
                    border: 1px solid $border-color;
                    margin-bottom: $gap-sm;
                    input {
                        padding: .5rem; //var?
                    }
                    .fav-list-pos-img-wrapper{
                        >img{
                            max-width: 100%;
                            object-fit: contain;
                        }
                    }
                }
                &:last-child{
                    .fav-list-position-wrapper{
                        margin-bottom: 0;
                    }
                }
            }
            >.fav-list-group-position{
                border: 1px solid $border-color;
                .fav-list-group-position-header{
                    display: flex;
                    justify-content: space-between;
                }
            }
        }
    }

.order-footer-buttons{
    display: flex;
    >div{
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        >button{
            min-width: 20em;
            max-width: 100%;
            padding: $m1 $gap;
            font-weight: bold;
            letter-spacing: 1px;
            &:first-child{
                margin: 0;
            }
        }
    }
}
#shopping-cart-positions-footer-wrapper {
    font-size: 1rem;
    #shopping-cart-positions-footer {
        background-color: $color-brand;
        color: $white;
        padding: $gap-sm;
        width: 100%;
        display: grid;
        .shopping-cart-positions-footer-inputs {
            display: grid;
            textarea {
                width: 100%;
                max-width: 100%;
                padding: .75rem;
                border: none;
                &:first-child{
                    margin-left: 0;
                }
            }
        }

        .shopping-cart-positions-footer-prices{
            width: 100%;
            >tbody{
                >tr{
                    >td{
                        padding-bottom: $m1;
                        span{
                            font-family: $font-family;
                            text-align: right;
                            white-space: nowrap;
                        }
                        &:nth-child(2n){
                            margin-right: $gap;
                            width: 1px;
                            white-space: nowrap;
                        }
                    }
                    &.shopping-cart-positions-footer-sum {
                        border-top: 2px solid $white;
                        padding-top: $gap-sm;
                        >td{
                            padding-top: $m1;
                        }
                    }
                }
            }
        }
    }
}
.cart-list-link-wrapper {
    margin: $gap;

    >a {
        padding: $gap-sm;
    }
}
#cart-sidebar {
    padding: 0;
    .cart-sidebar-wrapper {
        padding: $gap-sm;
        background-color: $white;
        button,label {
            text-transform: uppercase;
            padding: .5rem; //var?
        }
        .cart-sidebar-id-wrapper {
            .cart-sidebar-id--id {
                color: $color-brand;
            }
        }
        .cart-sidebar-actions{
            display: grid;
            grid-auto-flow: row; 
            grid-template-columns: repeat(2, minmax(0, 1fr)); 
            grid-auto-rows: 1fr;
            gap: .5em $gap-sm;
            button,label {
                background-color: $white;
                border: 1px solid $color-brand;
                color: $color-brand;
                align-items: center;
                display: flex;
                flex-wrap: wrap;
            
                >img {
                    height: 2em;
                    width: 2em;
                    object-fit: cover;
                    margin: 0 $m1;
                }
                cursor: pointer;
                svg {
                    margin: 0 $m1;
                }
                &.inactive {
                    color:$border-color;
                    border-color:$border-color;
                    cursor: default;
                    svg {
                        fill: $border-color;
                    }
                    img {
                        filter: grayscale(1);
                    }
                }

            }
        }
        .cart-sidebar-btn-list {
            >button,>a {
                margin-bottom: 0.5rem;
                color: $white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-wrap: wrap;
                    >img{
                        height: 2em;
                        width: 2em;
                        object-fit: cover;
                        margin-right: $m1;
                    }
                &:last-child{
                    margin-bottom: 0;
                }
            }
            >.cart-sidebar-import-parts-list-btn {
                margin-bottom: $gap;
            }
        }
    }
}
#shoppingCartList-component-wrapper {
    padding: $gap;
    flex-grow: 1;
    display: grid;
    gap: $gap $gap;

    h2 { color: $font-color; }
    #shoppingCartList-list-wrapper {
        grid-area: list-carts;
        .shoppingCartList-lsit-top {
            padding: $gap-sm;
            h3 { color: $white; }
        }
        #animation-wrapper {
            padding: 0;
            outline: 0;
            background: none;
            #shopping-cart-preview-wrapper {
                background-color: $white;
                position: relative;
                .shopping-cart-preview {
                    transition: 0.5s;
                    padding: $gap-sm;
                    align-items: flex-start;
                    row-gap: $gap;
                    &:hover {
                        background-color: $white;
                        color: $color-brand;
                        .shopping-cart-preview-overlay {
                            display: flex;
                        }
                    }
                    >.shopping-cart-preview-overlay {
                        display: none;
                        position: absolute;
                        height: 100%;
                        width: 100%;
                        top: 0;
                        left: 0;
                        background-color: $color-brand;
                        color: $white;
                        z-index: 100;
                        justify-content: center;
                        align-items: center;
                    }
                    &.false {
                        &:hover {
                            background-color: $white;
                            color: $color-brand;
                        }
                    }
                    &.true{
                        background: $color-brand;
                        color: $white;
                        h4, h3 {color: $white; }

                        &:hover {
                            background-color: $white;
                            color: $color-brand;
                            h4, h3 { color: $color-brand; }
                            .knr-btn {
                                background-color: $color-brand;
                                color: $white;
                            }
                        }
                    }
                }

                &:nth-of-type(1) {
                    position: relative;
                }
                &:nth-of-type(2) {
                    position: absolute;
                    z-index: 1;
                    top: 0;
                    left: 0;
                    //opacity: 0;
                }
            }
        }
    }
    #shoppingCartList-addCart-wrapper {
        display: flex;
        align-items: stretch;
        grid-area: add-cart;

        >div {
            width: 100%;
            background-color: $white;
            padding: $gap-sm;
        }
    }
}
.custom-modal-style {
    max-width: 500px;
    margin: 0 auto;
}
//animation
.play-slide-animation {
    animation: slide-in-cart 0.5s forwards;
    -webkit-animation: slide-in-cart 0.5s forwards;
}



@keyframes slide-in-cart {
    0% {
        transform: translateX(150%);
        opacity: 1;
    }
    99% {
        opacity: 1;
    }
    100% {
        transform: translateX(0%);
        opacity: 0;
    }
}
.hide-cart-animation {
    animation: hide-cart 0.5s forwards;
    -webkit-animation: hide-cart 0.5s forwards;
}
@keyframes hide-cart {
    0% {
        opacity: 0;
    }
    99% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@media only screen and (max-width: $bp-md) {
    #order-nav {
        padding: 0;
    }
    .shopping-cart-position {
        .shopping-cart-position-wrapper {
            margin: 0;
            .shopping-cart-pos-amount-wrapper {
                margin: 0;
                justify-content: flex-start;
                >span {
                    margin: 0 0 0.25rem 0;
                }
            }
        }
    }
    .order-sidebar-summary {
        margin-bottom: $gap-sm;
    }
    .cart-sidebar-btn-list {
        margin-top: $gap-sm;
        .cart-sidebar-import-parts-list-btn {
            margin-bottom: $gap-sm;
        }
    }
    .order-footer-buttons {
        margin-top: $gap-sm;
    }
}
@media only screen and (max-width: $bp-md) {
    .base-tile-sm {
        margin-bottom: 0;
        >.order-nav-item {
            >div, >a {
                padding: 0;
            }
        }
    }
}
@media only screen and (min-width: $bp-md) and (max-width: $bp-lg),
screen and (min-width: $bp-md) and (max-width: $bp-lg-retina) and (-webkit-min-device-pixel-ratio: 2) {
    #order-nav {
        padding: 0;
        >.order-nav-item {
            >div, >a {
                padding: $gap;
            }
        }
    }
    .base-tile-sm {
        margin-bottom: $gap-sm;
    }
    .shopping-cart-position {
        .shopping-cart-position-wrapper {
            margin: 0 0 $gap-sm;
            .shopping-cart-pos-amount-wrapper {
                >span {
                    margin-right: $gap-sm;
                }
            }
        }
    }
    .order-sidebar-summary {
        margin-bottom: $gap-sm;
    }
    .cart-sidebar-btn-list {
        margin-top: $gap-sm;
        .cart-sidebar-import-parts-list-btn {
            margin-bottom: $gap-sm;
        }
    }
}
@media only screen and (min-width: $bp-lg),
screen and (min-width: $bp-lg-retina) and (-webkit-min-device-pixel-ratio: 2) {
    #order-nav {
        padding: 0 $gap;
        >.order-nav-item {
            >div, >a {
                padding: $gap;
            }
        }
    }
    .order-sidebar-summary {
        margin-bottom: $gap;
    }
    .base-tile-sm {
        margin-bottom: $gap;
    }
    .cart-sidebar-btn-list {
        margin-top: $gap;
        .cart-sidebar-import-parts-list-btn {
            margin-bottom: $gap;
        }
    }
    .shopping-cart-position {
        .shopping-cart-position-wrapper {
            margin: 0 0 $gap;
            .shopping-cart-pos-amount-wrapper {
                >span {
                    margin-right: 0;
                }
            }
        }
    }
    #shopping-cart-positions-footer {
        grid-template-columns: repeat(2, 1fr);
        gap: $gap-sm;
        .shopping-cart-positions-footer-inputs {
            grid-template-columns: 2fr;
            grid-template-rows: 1fr;
        }
    }
}
@media (max-width: 1200px) {
    .shopping-cart-positions-footer-prices {
        >tbody{
            >tr{
                >td {
                    vertical-align: bottom;
                    text-align: left;
                }
            }
        }
    }
}
@media (min-width: 1200px) {
    .shopping-cart-positions-footer-prices {
        width: 100%;
        >tbody {
            >tr {
                >td {
                    vertical-align: top;
                    text-align: right;
                }
            }
        }
    }
}
@media (max-width: 992px) {
    #shoppingCartList-component-wrapper {
        grid-template-columns: 100%;
        grid-template-rows: repeat(2, auto);
        grid-template-areas:
            "add-cart"
            "list-carts";
        .shoppingCartList-lsit-top {
            margin-bottom: $gap-sm;
        }
        #animation-wrapper {
            margin-bottom: $gap-sm;
        }
    }
    .shopping-cart-positions-footer-inputs {
        gap: $gap-sm;
    }
    .shopping-cart-positions-footer-prices{
        width: 100%;
        margin-top: $gap-sm;
    }
}
@media (min-width: 992px) {
    #shoppingCartList-component-wrapper {
        grid-template-columns: 1fr 4fr;
        grid-template-rows: 100%;
        grid-template-areas:
            "add-cart list-carts";
        .shoppingCartList-lsit-top {
            margin-bottom: $gap;
        }
        #animation-wrapper {
            margin-bottom: $gap;
        }
    }
    .shopping-cart-positions-footer-textarea-comment { min-width: 30em; }
    .shopping-cart-positions-footer-inputs{ gap: $gap; }
    .shopping-cart-positions-footer-prices{ width: 100%; }
}
@media (max-width: 768px) {
    #order-nav {
        >.order-nav-item {
            padding: 1rem 0.5rem;
            .order-nav-item-index {
                min-width: 2.5em;
                >span {
                    font-size: 1.5em;
                }
            }
            .order-nav-item-title {
                >span{
                    font-size: 0.75em;
                }
            }
            .order-nav-spacer-wrapper {
                margin: 0 $gap-sm;
            }
            // &.active {
                display: flex;
                width: 90%;
                +.order-nav-spacer-wrapper {
                    display: flex;
                    width: 10%;
                    .order-nav-spacer {
                        font-size: 2.5em;
                    }
                }
            // }
        }
    }
    .shopping-cart-position-wrapper {
        row-gap: $m1;
        align-items: last baseline;
    }
}
@media (min-width: 768px) {
    #order-nav {
        .order-nav-spacer-wrapper {
            margin: 0 $gap;
            .order-nav-spacer {
                font-size: 5em;
            }
        }
        >.order-nav-item {
            padding: 0 $gap;
            .order-nav-item-index {
                min-width: 2.5em;
                >span {
                    font-size: 2em;
                }
            }
            .order-nav-item-title {
                >span{
                    font-size: 1em;
                }
            }
            &.active {
                display: flex;
                +.order-nav-spacer-wrapper { display: flex; }
            }
            &.inactive {
                display: flex;
                +.order-nav-spacer-wrapper { display: flex; }
            }
        }
    }
    .shopping-cart-position-wrapper {
        align-items: center;
    }
}
@media (max-width: 576px) {
    .shopping-cart-positions-footer-prices{
        width: 100%;
        >tbody{
            >tr{
                >td { padding-right: 0; }
            }
        }
    }
    .order-footer-buttons {
        flex-wrap: wrap;
        gap: $gap-sm;
        >div {
            flex-basis: 100%;
            gap: $gap-sm;
            >button {
                flex-basis: 100%;
            }
        }
    }
}
@media (min-width: 576px) {
    .shopping-cart-positions-footer-prices {
        width: 100%;
        >tbody{
            >tr{
                >td { padding-right: $gap; }
            }
        }
    }
    .order-footer-buttons {
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: flex-start;
        gap: $gap;
        >div {
            gap: $gap;
            flex-basis: unset;
            align-items: flex-start;
        }
    }
}
