#signin-component-wrapper {
  padding: $gap;
  flex-grow: 1;
  .signin-component-title {
    background-color: $color-brand;
    padding: $gap-sm;
    margin-bottom: $gap-sm;
    h3 { color: $white; }
  }
  h6 { font-weight: bold; }
  .signin-component-section {
    background-color: $background-tile;
    padding: $gap-sm;
    grid-template-columns: 1fr;
    grid-template-rows: auto;

    [class^='signin-input-'], [class*=' signin-input-'] {
      margin: $m2 0;
    }
    &.pw-reset-request--server-error-dispay {
      color: $mui-red;
      padding: 0;
      margin-bottom: $m2;
    }
  }
}

#register-component-wrapper {
  flex-grow: 1;
  margin: $m6 auto;
  .register-component-title {
    background-color: $color-brand;
    padding: $gap-sm;
    margin-bottom: $gap-sm;
    h3 { color: $white; }
  }
  .register-err-wrapper {
    color: $mui-red;
    margin-bottom: $m2;
  }
  .register-pw-input-wrapper {
    .register-pw-hint {
      color: $color-brand;
    }
  }
  h6 { font-weight: bold; }
  .register-component-section {
    background-color: $background-tile;
    padding: $gap-sm;

    [class^='register-input-'], [class*=' register-input-'] {
      //margin: $m2 0;
    }
    .register-component-grid{
      .register-component-grid-item {
        margin-bottom: $gap-sm;
        .MuiFormControl-root{
          .MuiInputBase-root{
            margin-bottom:0 !important;
          }
        }

      }
}
  }
}
.register-component-confirmation-wrapper {
  padding: $gap-sm;
  max-width: 50rem;
  >p {
    &:first-child {
      font-size: 1.5rem;
      font-weight: bold;
      color: $font-color-darker;
    }
    &:nth-child(2) {
      font-size: inherit;
      color: $font-color;
    }
  }
  .pw-reset-btn-wrapper {
    display: flex;
    justify-content: space-between;
  }
}
