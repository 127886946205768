.h1,.h2,.h2-thin,.h3,.h4,.h5,
h1, h2, h3, h4, h5 {
  text-transform: uppercase;
  color: $color-brand;
  letter-spacing: 0.05em;
  font-weight: bold;
  letter-spacing: 1px;
}
h1, .h1 {
  font-size: 2rem;
  font-family: $font-family-bold;
  font-weight: normal;
  letter-spacing: 0.0125em;
}
h2, .h2 {
  font-size: 1.3rem;
  font-family: $font-family-bold;
  font-weight: normal;
}
.h2-thin{ font-size: 1.3rem; }
h3, .h3{
  font-size:1.1rem;
  font-weight: bold;
}
.font-bold {
  font-family: $font-family-bold;
  font-weight: normal;
}
.text-bold{
  font-weight: bold;
}
.text-small{
  font-size: .8em;
}
.color-default {
  color:$font-color !important
}
.color-brand {
  color:$color-brand !important
}
.color-white {
  color:$white !important
}
.color-inactive {
  color:$grey-light !important
}
.bgcolor-brand {
  background-color:$color-brand !important
}
.bgcolor-th {
  background-color:$grey6C !important
}
.hr-white{
  color:$white !important;
  background-color:$white !important;
  border-top:none;
}
.box-shadow1{
  box-shadow: $box-shadow1;
}

input{
  border-radius: 0;
  border: 1px solid $grey-light
}
// default
.divider { margin: 0 $m2; }
.divider-primary { border-top: 1px solid $color-brand; }
.divider-secondary { border-top: 1px solid $border-color; }

.product-list-header{
  display: flex;
  justify-content: space-between;
}
  .product-list-item-ejector-infos{

    &.product-list-item-ejector-infos--row{
    }
    &.product-list-item-ejector-infos--block{
      position:absolute;
      left:$gap-sm;
      right: $gap-sm;
      bottom: $gap-sm;
    }
    img{
      width: $gap;
      height: $gap;
    }
    span{
      color: #000 !important;
    }
  }
.product-list-item-wrapper{
  font-size: 1rem;
  padding: 0;
  text-decoration: none;
  position: relative;


  .product-list-item, .special-list-item {
    height: 100%;
    position: relative;
    box-shadow: 0 0 0 0 transparent;
    transition: all .3s ease;
    .product-list-item-number{
      position: absolute;
      font-size: .8em;
      top: -0.5em;
      left: 2em;
      color: $color-brand;
      background-color: $white;
      z-index: 1;
      padding: .25em .5em;
    }
    .product-list-item-inner, .special-list-item-inner{
      border:2px solid $color-brand;
      position: relative;
      padding: 1.5em;
      height: 100%;
    }
    .special-list-item-inner{
      background-color: $color-brand;
    }
    .product-list-item-saletag{
      position: absolute;
      top:-1px;
      right: -1px;
      background-color: $color-brand;
      font-size: 1.2em;
      color: $white;
      text-align: center;
      padding: .25em 1em;

    }
    .product-list-item-name, .special-list-item-name{
      color:$font-color;
      position: absolute;
      text-transform: uppercase;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: bold;
      width: 100%;
      left: 0;
      padding: inherit;
    }
    .special-list-item-name{
      top: 50%;
      transform: translateY(-50%);
      font-family: $font-family-bold;
      color: $white;
      text-align: center;
      font-size: 1.2em;
      letter-spacing: 1px;
    }
    .product-list-item-image, .special-list-item-image{
      position: absolute;
      display: flex;
      align-items: flex-end;
      left: 0;
      right: 0;
      top: 30%;
      bottom: 0;
      overflow: hidden;
      >img{
        width: 100%;
        height: 100%;
        object-fit: contain;
        transition: transform .5s ease;
      }
      &.product-list-item-image--placeholder{
        padding: $gap;
      }
      &.product-list-item-image--ejector{
        top: 0;
      }
    }
    .special-list-item-image{
      top: 0;
      padding: $gap-sm;
    }
    &:hover{
      box-shadow: $box-shadow1-down;
      transform: translateY(-3px);
      .product-list-item-image, .special-list-item-image{
        >img{
          transform: scale(1.05);
        }
      }
    }
  }
  &.product-list-item-wrapper--square{
    &::before {
      content: "";
      display: block;
      width: 100%;
      padding-top: 100%;
    }
    >.product-list-item, >.special-list-item{
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
  &.product-list-item-wrapper--tile{
    background-color: $white;
    padding: $gap-sm;
    /* .product-list-item-number {
      //top: -0.6em;
    } */
  }
  &.product-list-item-wrapper--row{
    grid-column-start: 1;
    grid-column-end: 7;
    grid-gap:.5em;
    .product-list-item {
      box-sizing: border-box;
      margin-bottom: 1em;
      &:hover {
        background-color: #fff;
        z-index: 1;
        transform: none;
        box-shadow: -8px 0 0 0 #00949C, 0 6px 6px 0 rgba(0,0,0,.2), 0 0 6px 0 rgba(0,0,0,.2);
      }
      .product-list-item-inner {
        padding: 0;
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 1fr 3fr 3fr;
        grid-template-areas: "image number name infos";
        border: none;
        border-bottom: 2px solid #00949C;
        >.product-list-item-image {
          position: static;
          grid-area: image;
          >img{
            min-height: 3em;
            max-height: 5em;
            max-width: 100%;
            width: auto;

          }
        }
        >.product-list-item-number {
          text-align: left;
          position: static;
          grid-area: number;
        }
        >.product-list-item-name {
          position: static;
          grid-area: name;
        }
        >.product-list-item-ejector-infos {
          position: static;
          grid-area: infos;
        }
      }
    }
    .special-list-item{
      .special-list-item-inner{
        padding: 2em;
      }
    }
  }
}

.f-gap{gap:$gap;}
.f-gap-sm{gap:$gap-sm;}

.collapse-group-wrapper{
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  margin-bottom: $gap-sm;
  border: 1px solid $grey-light;

  .btn-collapse-toggle{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1px;
    padding: .5em;
    width: 100%;
    border:none;
    box-shadow: 0 1px 0 0 $grey-light;
    transition: box-shadow .4s ease;
    >svg{
      transition: transform .3s ease;
    }
  }
  &.active{
    .btn-collapse-toggle{
      box-shadow: 0 1px 0 0 $color-brand;
    }
  }
  .collapse-group-children{
    >div{
      padding: $gap-sm $gap-sm 0;
    }
  }
}

.product-list-wrapper{
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  grid-template-rows: repeat(auto-fill, 1fr);

  &.product-list-wrapper-type--row {
    grid-gap: 4px;

    .product-list-item-wrapper--row {
      padding: 0;
      margin: 0;

      .product-list-item-image--placeholder{
        padding: $gap-sm;
      }
    }
  }
}
.fullwidth-component-wrapper{
  margin: $gap;
  padding: $gap-sm $gap;
  background-color: $background-tile;
  flex-grow: 1;
}


.formOptionsGrid{
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-auto-rows: auto;
  grid-auto-flow: row;
  gap: .1rem $gap-sm;
}
.no-content-page{
  background-color: $white;
  font-weight: bold;
  display: flex;
  padding: $gap;
  font-size: 1.1rem;
  height: 100%;
  justify-content: center;
}
.input-slider-label{
  >span{
    font-weight: bold;
  }
}

.base-tile{
  padding: $gap;
  background-color: $white;
}
.base-tile-sm{
  padding: $gap-sm;
  background-color: $white;
}
.brand-tile{
  padding: $gap;
  background-color: $color-brand;
}
.brand-tile-sm{
  padding: $gap-sm;
  background-color: $color-brand;
}

.table-unstyled{
  tr{
    background: none !important;
    
    td{
      padding: .25em ;
      border: none;
    }
  }
}
.loading-overlay-wrapper{
  display: flex;
  position: absolute;
  top:0;
  left:0;
  right: 0;
  bottom:0;
  background-color: rgba(255,255,255,.5);
  align-items: center;
  justify-content: center;
  z-index: 9999;
  padding: $gap;
  >div{
    transform: scale(2);
  }
}


#pli-import-textarea{
  min-height: 5em;
}
.pli-table-head{
  min-width: 50em;
  max-width: 100%;
  display: flex;
  border-top: 1px solid $border-color;
  border-bottom: 1px solid $border-color;
  >.MuiFormControl-root{
    margin: 0 !important;
    width: 1px;
    flex-grow: 1;
    border-right: 1px solid $border-color;
    &:first-child{
      border-left: 1px solid $border-color;
    }
    &:hover{
      background-color: rgba($color: $color-brand, $alpha: .1);
    }
    >.MuiInputBase-root{
      margin: 0 !important;
      >fieldset{
        border: none !important;
      }
    }
    >select{
      background: none;
      border: none;
      padding: $m1;
      background-color: $white;
      color: $font-color;
    }

  }
}

.pli-table-body{
  min-width: 50em;
  max-width: 100%;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  .pli-table-row{
    border-bottom: 1px solid $border-color;
    display: flex;
    flex-direction: row;
    .pli-table-cell{
      height: 1.5em;
      border-right: 1px solid $border-color;
      width: 1px;
      flex-grow: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0.2em;
      &:first-child{
        border-left: 1px solid $border-color;
      }
      &:hover {
        background-color: rgba($color: $color-brand, $alpha: .1);
      }
      >.pli-table-cell-label{
        width: 100%;
      }
    }
  }
}
.app-language-flag {
    width: 1.5em;
    margin-right: $gap-sm;
    box-shadow: 0 .2em .5em .1em rgba(0,0,0,.2);
}

.events-wrapper {
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-rows: 1fr;
  gap: $gap $gap;
  height: 100%;

  .events-item {
    position: relative;
    padding: 0.75rem 1.25rem 2rem;
    background-color: $utility-nav-color;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-decoration: none;

    >.events-item-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 1;
      filter: brightness(.9)
    }

    >.events-item-content {
      z-index: 2;
      text-shadow: 0px 0px 1em rgba(0, 0, 0, .5);
    }
  }
}


@media only screen and (max-width: $bp-xxl) {

}

@media only screen and (max-width: $bp-xl) {
  .product-list-wrapper {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media only screen and (max-width: calc($bp-xl - 150px)) {
  .product-list-wrapper {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media only screen and (max-width: calc($bp-xl - 300px)) {
  .product-list-wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (max-width: $bp-lg),
screen and (max-width: $bp-lg-retina) and (-webkit-min-device-pixel-ratio: 2) {
  .product-list-wrapper {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media only screen and (min-width: $bp-md) {
  .product-list-wrapper {
    grid-gap: $gap;
  }
}
@media only screen and (max-width: $bp-md) {
  .product-list-wrapper {
    gap:$gap-sm;
    grid-template-columns: repeat(3, 1fr);
  }
  .fullwidth-component-wrapper{
    margin: $gap-sm;
    padding: $gap-sm;
  }
}

@media only screen and (max-width: $bp-sm) {
    .product-list-wrapper {
      grid-template-columns: repeat(2, 1fr);
    }
    .fullwidth-component-wrapper {
      margin: $m1;
      padding: $m1;
    }
}
@media only screen and (max-width: $bp-xs) {
    .product-list-wrapper {
      grid-template-columns: repeat(1, 1fr);
    }
}