.MuiPaper-root{
  border-radius: 0 !important;
}
.MuiTabs-root{
  overflow-x: auto;
}
.MuiSlider-root{
  height: 6px !important;
  border-radius: 0 !important;
  .MuiSlider-rail{
    opacity:unset;
    &::before, &::after{
      content: "";
      width: 1em;
      background-color: inherit;
      position: absolute;
      height: 6px;
    }
    
    &::before{
      right: 100%;
      border-radius: 12px 0 0 12px;
    }
     &::after{
      left: 100%;
      border-radius: 0 12px 12px 0;
    }
  }
  .MuiSlider-mark{
    background-color: #30c4cC;
  }
  .MuiSlider-thumb{
    color: $white;
    &::before{
      background-color: $color-brand;
      border: 2px solid white;
      box-shadow:$box-shadow1, $box-shadow1-down;
    }
    &::after{
      content: unset;
    }
    .MuiSlider-valueLabel{
    font-family: $font-family;
      //bottom:-10px;
      top: 45px;
      border-radius: 0;
      color: $font-color;
      background-color: $white;
      box-shadow: $box-shadow1-down;
      padding: .25em .5em;
      &::before{
        content: unset;
        bottom: unset;
        top: -10px;

      }
    }
  }
  .MuiSlider-markLabel{
    font-family: $font-family;
    font-size: .8rem;
      color: $grey;
    &.MuiSlider-markLabelActive{
      color: $color-brand;
    }
  }
}
.MuiOutlinedInput-root{
  background-color: $white;
}
.MuiFormControl-root, .MuiOutlinedInput-root {
  width: 100%;
  margin: 0 !important;
  border-radius: 0 !important;

  .MuiFormControlLabel-root{
    .MuiCheckbox-root{
      transition: color .3s ease;
      &.Mui-checked{
        color:$color-brand;
      }
      &:hover{
        
        color:$color-brand;
      }
    }
    .MuiTypography-root{
      font-family: $font-family;
      font-weight: bold;
      a{
        color: $color-brand;
        &:hover{
          color: $color-brand-darker;
        }
      }
    }
  }
  .MuiFormLabel-filled{
    background: #fff;
    padding: 0px 4px;
    letter-spacing: 1px;
  }
  .MuiSelect-select{
    //padding: .5em .75em;
    min-width: 3em;
  }
}
.MuiBreadcrumbs-root{
  .MuiBreadcrumbs-li{
    a{
      font-family: $font-family;
      font-weight: bold;
      text-decoration: none;
      color: $font-color;
    }
    p{
      font-family: $font-family;
      font-weight: bold;
      color: $color-brand;
    }
  }
}

.MuiTextField-root{
  .MuiInputBase-root{
    border-radius: 0 !important;
  }
}

.MuiTooltip-tooltip {
  font-size: inherit!important;
}

.MuiPagination-root{
  .MuiPaginationItem-root{
    border-radius: 0;
  }
}