#product-view-page-wrapper {
  display: grid; 
  gap: 0 $gap; 
  >#product-view-info-head{
    margin-bottom: $gap;
  }
  #product-view-info-attributes{
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: auto;
    grid-gap: 0px 0px;
    gap: $gap-sm $gap-sm;
    >.product-view-info-attributes-subgrid{
        /* display: grid; 
        grid-template-columns: 6em 1fr; 
        grid-template-rows: 1fr; 
        gap: 0px 0px; 
        grid-template-areas: 
          "g-title g-value"; */
        gap: .5em;
        display: flex;
        flex-direction: column;
        >.g-title{
          font-weight: bolder;
          //grid-area: g-title; 
        } 
        >.g-value{
          //padding-left: .5em;
          //grid-area: g-value; 
        } 
    }
  }
  >#product-view-info {
    // grid-area: info;
    > #product-view-info-inner{
      position: sticky;
      z-index: 12;
      top: 1em;
      max-height: 100vh;
      overflow: auto;
    }
    .product-view-image-wrapper{ 
      overflow:hidden;
      >img {
        max-width: 100%;
        transition: transform .3s ease;
      }
      &:hover{
        >img{
          transform: scale(1.05);
        }
      }
    }
    
    #product-view-info-utils{
      display: flex;
      margin: $gap 0;

      >a,>button {
        display: flex;
        margin-right: $gap-sm;
        font-size: 1.2rem;
        text-transform: uppercase;
        >div{
          margin-right: $m1;
        }
        &:last-child{
          margin-right: 0;
        }
      }
    }
  }
  #product-view-extra-texts{ 
    padding: $gap;
    >ul{ 
      list-style: none;
      > li {
        font-size: 1.2em;
        line-height: 1.5em;
        margin-bottom: $m1;
        &::before {
          content: "\2022";
          color: $color-brand;
          font-weight: bold;
          display: inline-block;
          width: 1em; 
          margin-left: -1em;
        }
      }
    }
  }
  >#product-view-main {
    grid-area: table; 
    min-width: 0;
    >#product-view-table{
      width: 100%;
    }
  }

}
.special-switch{
  display: flex;
  >label{
    &:first-child{
      margin-right: 2.75rem;

    }
   /*  &:last-child{
    } */
    &:first-child, &:last-child{
      &.active{
        color: $color-brand;
      }
    }
  }
  &.custom-switch{
   /*  .custom-control-label::before {
      // top: 0.1rem;
    } */
    .custom-control-label::after {
      /* top: calc(.1rem + 2px); */
      background-color: $white;
      transform: translateX(0.75rem);}
    .custom-control-input:checked ~ .custom-control-label::after {
      background-color: #fff;
      transform: translateX(0rem);
    }
    .custom-control-input ~ .custom-control-label::before,
    .custom-control-input:checked ~ .custom-control-label::before {

      color: #fff;
      border-color: $color-brand;
      background-color: $color-brand;

    }
  }
}
.product-list-util-grid{
  border: 1px solid black;
   display: grid; 
   padding: $gap-sm 0;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr; 
  grid-template-rows: 1fr; 
  gap: $gap-sm 0px; 
  grid-template-areas: 
    "lg-utils lg-options lg-options lg-amount-label lg-amount lg-cartbtn lg-cartbtn"; 
  &.product-list-util-grid-var1{
    grid-template-rows: 1fr 1fr;
    grid-template-areas: 
    "lg-utils lg-options lg-options lg-length-label lg-length lg-tolerance lg-price"
    "lg-utils lg-options lg-options lg-amount-label lg-amount lg-cartbtn lg-cartbtn";
  }
    .lg-utils { 
      grid-area: lg-utils;
      padding-left: $gap-sm; 
      .btn-table-list-utils{
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }
    .lg-options { 
      grid-area: lg-options; 
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: start;
      padding: 0 $gap-sm;
      .form-check{
        margin-bottom: $m1;
        &:last-child{
          margin-bottom: 0;
        }
        >label{
          display: flex;
          align-items: center;
        }
      }
      .lg-options-comment{
        display: flex;
        flex-direction: column;
        height: 100%;
        gap: $gap-sm;
      }
    }
    .lg-price { 
      grid-area: lg-price;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: $font-family-bold;
    }
    .lg-cartbtn { 
      grid-area: lg-cartbtn;
      padding-right: $gap-sm;
      display: flex;
      align-items: center;
      // padding-left: calc($gap-sm /2);
      >button{
        width: 100%;
      }
     }
    .lg-tolerance { 
      grid-area: lg-tolerance;
      padding-right: $gap-sm;
      display: flex;
      align-items: center;
    }
    .lg-amount { 
      grid-area: lg-amount;
      padding-left: $gap-sm;
      padding-right: $gap-sm;
      display: flex;
      align-items: center;
     }
    .lg-amount-label { 
      grid-area: lg-amount-label; 
      display: flex;
      justify-content: flex-end;
      align-items: center;
      pointer-events: none;
    }
    .lg-length { 
      grid-area: lg-length;
      padding: 0 $gap-sm; 
      >.input-group {
        input{
          padding-right: 0;
        }
      }
    }
    .lg-length-label { 
      grid-area: lg-length-label;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      pointer-events: none;
     }
}

#product-view-gallery{
  display: flex;
  flex-direction: column;
  margin-bottom: $gap;
  >.product-view-gallery-thumbnails{
  display: grid; 
  grid-auto-rows: 1fr; 
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr; 
  gap: $gap-sm; 
    >.product-view-gallery-thumbnail{
      padding: 0;
      border: 1px solid $border-color;
      background-color: #fff;
      overflow: hidden;
      transition: all .3s ease;
      min-height: 5em;

      >img{
        max-width: 100%;
        object-fit: contain;
      }
      &.active{
        border-color: $color-brand;
      }
      &:hover{
        transform: translateY(-3px) scale(1.05);
        box-shadow: $box-shadow1-down;
      }
    }
  }
  >.product-view-gallery-image-wrapper{
    width: 100%;
    margin-bottom: $gap-sm;
    >.product-view-gallery-image-container{
      width: 100%;
      position: relative;
      overflow: hidden;
      &::before{
        content:"";
        width:100%;
        display: block;
        //background-color:#f8f8f8;
        padding-top:60%;

      };
      >.product-view-gallery-image{
        display: flex;
        align-items: center;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top:0;
        transition: transform .3s ease;
        >img{
          max-width: 100%;
          object-fit: contain;
        }
        >.product-view-gallery-image-utils{
          position: absolute;
          bottom:$gap-sm;
          right: $gap-sm;
          display: flex;
          >*{
            background-color: $color-brand;
            border:none;
            padding: .5em;
            transition: background-color .3s ease;
            margin-right: $gap-sm;
            &:hover{
              background-color: $color-brand-darker;
            }
            &:last-child{
              margin-right: 0;
            }
          }


        }
        &.active{
          z-index: 2;
        }
        &.inactive{
          transform: translateX(100%);
          z-index: 1;
        }
      }
    }
  }
}
.product-view-gallery-modal{
  background-color: rgba(0,0,0,.1);
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: $gap;
  .product-view-gallery-modal-inner{
    max-width: calc(100% - 2rem);
    max-height: 100%;
    display: flex;
    >img{
      object-fit: contain;
      max-width: 100%;
      max-height: 100%;
    }
    >video{
      background-color: rgba(0,0,0,.1);
      object-fit: contain;
      width: 1500px;
      max-width: 100%;
      max-height: 100%;
    }
    
  }
}

@media only screen and (max-width: $bp-lg),
screen and (max-width: $bp-lg-retina) and (-webkit-min-device-pixel-ratio: 2) {
  #product-view-page-wrapper {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto 1fr;
    grid-template-areas: 
      "info" "switch" "table-head" "table";
      >#product-view-table-head {
        grid-area: switch;
        .special-switch {
          padding-left: 0;
        }
      }
  }
}
@media only screen and (min-width: $bp-lg) {
  #product-view-page-wrapper {
    grid-template-columns: 1fr 3fr; 
    grid-template-rows: auto 1fr; 
    grid-template-areas: 
    "info table-head" "info table";
    >#product-view-table-head{
      grid-area: table-head; 
    }
    >#product-view-info-head{
      grid-area: info-head; 
      margin-bottom: $gap;
    }
    >#product-view-info {
      grid-area: info;
    }
    gap: 0 $gap;
  }
}
@media only screen and (min-width: $bp-md) {
  #product-tabpanel-upsell {
    #product-view-upsell {
      .product-list-header {
        margin: $gap 0;
      }
    }
  }
  #product-view-info-utils {
    margin: $gap 0;
  }
}
@media only screen and (max-width: $bp-md) {
  #webshop-breadcrumb {
    display: none;
  }
  #product-tabpanel-info {
    &.pos-sm {
      #product-view-info-attributes {
        display: grid;
        grid-auto-flow: row;
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: auto;
        grid-gap: 0px 0px;
        grid-gap: 1.236rem 1.236rem;
        gap: 1.236rem 1.236rem;
      }
    }
  }
  #product-tabpanel-upsell {
    #product-view-upsell {
      .product-list-header {
        margin: $gap-sm 0;
      }
    }
  }
  .procuctView-tabList {
    >div {
      height: fit-content;
    }
    grid-area: table-head;
  }
  #product-view-info {
    grid-area: info;
  }
  #product-view-main {
    grid-area: table;
    #product-tabpanel-info {
      padding: $gap-sm 0;
    }
  }
  #product-view-info-utils {
    margin: $gap-sm 0;
    a {
      margin-right: $gap-sm;
    }
  }

  .product-list-util-grid {
    grid-template-columns: 1fr 1fr !important;
    grid-template-rows: auto auto auto auto auto !important;
    padding: $gap-sm !important;
    grid-template-areas:
      "lg-utils lg-options" "lg-utils lg-options" "lg-length-label lg-length" "lg-tolerance lg-price" "lg-amount-label lg-amount" "lg-cartbtn lg-cartbtn" !important;
    .lg-utils {
      padding-left: 0;
    }

    .lg-options {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      padding: 0 0 0 $gap-sm;

      .form-check {
        margin-bottom: $m1;
        &:last-child {
          margin-bottom: $gap-sm;
        }
      }
      .lg-options-comment{
        width: 100%;
        input{
          width: 100%;
        }
      }
    }

    .lg-price {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: $font-family-bold;
    }

    .lg-cartbtn {
      padding-right: 0;
    }

    .lg-tolerance {
    }

    .lg-amount {
      padding-right: 0;
    }

    .lg-amount-label {
    }

    .lg-length {
      padding: 0 0 0 $gap-sm;
    }

    .lg-length-label {
    }
  }

}
@media only screen and (min-width: $bp-sm) {
  #product-view-page-wrapper {
    .MuiTabs-root.procuctView-tabList {
      display: none;
    }  
    .MuiTabs-flexContainer {
      display: none;
    }
  }

  #product-tabpanel-info {
    &.pos-sm {
      display: none;
    }
  }
}
@media only screen and (min-width: $bp-sm) and (max-width: $bp-lg) {
  #product-view-info-inner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: "title title" "gallery info";
    h2 { grid-area: title; }
    .product-view-gallery { grid-area: gallery; }
    #product-tabpanel-info {
      grid-area: info!important;
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin-left: $gap;
    }
  }
}
@media only screen and (max-width: $bp-md) {
  .product-view-gallery-modal{
    padding: 0;
  }

}
@media only screen and (max-width: $bp-sm) {
  #product-tabpanel-info {
    &.pos-lg {
      display: none;
    }
  }
}

@media only screen and (max-width: $bp-xs) {

}
