.mui-carousel-jumbotron {
  .mui-carousel-inner {
    height: 100%;

    .mui-carousel-item {
      min-height: 40rem;
      height: 50vh;
      max-height: 100vh;

      >img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      >.mui-carousel-caption {
        position: absolute;
        bottom: 50%;
        left: 10%;
        max-width: 80%;
        transform: translateY(50%);
        letter-spacing: .15em;
        text-align: start;
        padding-top: 0;
        padding-bottom: $gap-sm;

        h2 {
          font-family: $font-family-bold;
          color: $white;
          font-size: 2.5rem;
          line-height: 1em;
          text-shadow: 0 5px 10px rgba(0, 0, 0, .5), 0 0 4px rgba(0, 0, 0, .25);
        }

        p {
          margin-top: $gap-sm;
          color: $white;
          font-size: 2rem;
          line-height: 1em;
          text-shadow: 0 5px 10px rgba(0, 0, 0, .5),
            0 0 4px rgba(0, 0, 0, .25);
          padding: 5px 10px 15px;
          transform: translateX(-10px);
          max-height: 16rem;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .mui-carousel-caption-button {
          margin-top: 1.618rem;
          display: inline-block;
          background-color: $white;
          border: 1px solid $white;
          padding: $m2 $gap-sm;
          color: black;
          transition: all .2s ease;
          letter-spacing: 2px;
          text-decoration: none;

          &:hover {
            background-color: transparent;
            border-color: rgba($color: $white, $alpha: .1);
            ;
            color: white;
          }
        }
      }
    }
  }

  .mui-carousel-indicators {
    position: absolute;
    bottom: $gap;
    z-index: 1;

    >button {
      width: $gap-sm;
      height: $gap-sm;
      border-radius: 7em;
      border: none;
      opacity: unset;
      margin: 0 .5rem;
      background-color: $white;

      &.active {
        background-color: $color-brand;
      }
    }
  }
}

#landingpage-component-wrapper {
  .carousel-jumbotron {
    height: 50vh;
  }

  .carousel-indicators {
    >li {
      margin: 0 .5rem;
    }
  }
}


@media only screen and (max-width: $bp-sm) {
  .mui-carousel-item {
    min-height: 18rem;
    height: 20vh;
  }
}