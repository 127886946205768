#order-component-wrapper{
  position: relative;
  flex-grow: 1;
  >#order-confirmation-wrapper{
    position: absolute;
    top:0;
    right:100%;
    width:100%;
    bottom: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content:center;
    background-color:  transparent;
    padding: $gap;
    transition: background-color .6s ease;
    overflow: hidden;

    >.order-confirmation-inner{
      position: relative;
      max-width: 100%;
      transition: transform .6s ease;
      transform:translateX(-100%);
      >.order-confirmation-content{
        display: flex;
        padding-top: $gap;
        padding-bottom: $gap;
        >.order-confirmation-icon{
          display: flex;
          align-items: center;
          justify-content: center;
          width: 50%;
          >svg{
            max-width: 100%;
          }
        }
        >.order-confirmation-text{
          width: 50%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: $gap 0;
          >.order-confirmation-title{
            font-family: $font-family-bold;
            font-size: 3.2rem;
            letter-spacing: 1px;
          }
          >.order-confirmation-message{
            font-size: 1.2rem;
            letter-spacing: 1px;
          }
          >.order-confirmation-sum{
            max-width: 100%;
            width: 20rem;
            justify-content: space-between;
            display: flex;
            >span{
              font-size: 1.2rem;
              letter-spacing: 1px;
              text-transform: uppercase;
              color: $color-brand;
            }
          }
          .order-confirmation-btn {
            &.pw-reset-btn-wrapper {
              display: flex;
              column-gap: $gap;
            }
            &>a {
              max-width: 100%;
              width:20rem;
            }
          }
        }
      }
    }
    >.order-confirmation-footer{
      position: absolute;
      bottom:0;
      border-top:1px solid $font-color;
      padding: 0 $gap;
      min-width: 50%;
      max-width: 95%;
      display: flex;
      justify-content: center ;
      transform: translateY(100%);
      transition: transform .6s ease;
      >div{
        display: flex;
        >a{
          display: block;
          padding: $gap;
          &:hover{
            color: $color-brand;
          }
        }
      }
    }
    &.active{
      transition: background-color 1.2s ease;
      background-color:  $grey-light;
      right: 0;
      >.order-confirmation-inner{
      transform:translateY(0%);
      }
      >.order-confirmation-footer{
        transform: translateY(0%);
      }

    }
  }
}
.ordering-page-grid {
  display: grid;
  grid-template-columns: 1fr 4fr; 
  grid-template-rows: auto auto;
  grid-template-areas: 
    "o-sidebar o-main"
    "o-sidebar o-footer"; 
  width: 100%; 
  height: auto;
  .ordering-page-sidebar{
    grid-area: o-sidebar;
  }
  .ordering-page-main{
    grid-area: o-main;
    width: 100%;
  }

  .ordering-page-footer{
    grid-area: o-footer;
  }
}

.order-sidebar-summary {
  color: $white;
  padding: $gap-sm;
  display: flex;
  flex-direction: column;
  .order-sidebar-summary-sum-gross {
    text-transform: uppercase;
    margin: $m1 0;
  }
  button {
    color: $color-brand;
    background-color: $white;
    border: none;
    &.order-sidebar-summary-next-btn {
      &.disabled, &:disabled{
        background-color: $grey;
        border-color: $grey;
        color: $white;
      }
    }
  }
}
.ordering-page-options {
  padding: 0 $gap;
  margin-top: $gap;
  margin-right: $gap-sm;
  text-align: right;
  >.MuiFormControl-root{
    width: auto;
    padding: 0;
    margin: 0 !important;
  }
}

.ordering-options-list {
    display: flex;
    grid-gap: $gap-sm;
  .ordering-options-item{
      padding: $gap-sm;
      margin-right: $gap-sm;
      border: 1px solid $border-color;
      &:last-child{
        margin-bottom: 0;
      }
  }
}
@media only screen and (max-width: $bp-md) {
  .ordering-page-grid {
    grid-gap: 0;
  }
}
@media only screen and (min-width: $bp-md) and (max-width: $bp-lg),
screen and (min-width: $bp-md) and (max-width: $bp-lg-retina) and (-webkit-max-device-pixel-ratio: 2) {
  .ordering-page-grid {
    grid-gap: $gap-sm;
  }
}
@media only screen and (max-width: $bp-lg),
screen and (max-width: $bp-lg-retina) and (-webkit-max-device-pixel-ratio: 2) {
  .ordering-page-grid {
    padding: 0;
    grid-template-areas:
      "o-sidebar o-sidebar"
      "o-main o-main"
      "o-footer o-footer";
    .order-sidebar-summary {
      margin: $gap-sm 0 $gap-sm;
    }
  }
}
@media only screen and (min-width: $bp-lg),
screen and (min-width: $bp-lg-retina) and (-webkit-max-device-pixel-ratio: 2) {
  .ordering-page-grid {
    grid-gap: $gap;
    padding: 0 $gap $gap;
    grid-template-areas:
    "o-sidebar o-main"
    "o-sidebar o-footer";
    .order-sidebar-summary {
      margin: $gap-sm 0 $gap;
    }
  }
}