#adm-lps-wrapper{
  margin-top: $gap-sm;
  .adm-lps-slides-list{
    margin-top: $gap-sm;
    .adm-lps-option{
      padding: $gap;
      margin-bottom: $gap;
      box-shadow: $box-shadow1, $box-shadow1-down;

      .adm-lps-option-main{
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        grid-template-rows: auto;
        gap: $gap-sm;
        margin-bottom: $gap-sm;
      }
      .adm-lps-option-img{
        max-width: 100%;
        max-height: 500px;
        min-height: 200px;
        background: $grey;
        box-shadow: $box-shadow1-down;
        >img{
          max-width: 100%;
          max-height: 100%;
          object-fit: contain;}
      }
      .adm-lps-buttons {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        grid-template-rows: auto;
        gap: $gap-sm;
        margin-bottom: $gap-sm;
      }
      .adm-lps-option-texts{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        grid-template-rows: auto;
        gap:$gap-sm;
        .adm-lps-language-inputs{
          padding: $m1;
          &:nth-child(2n){
            background-color: rgba(0,0,0,.025);
          }
          .adm-lps-language-inputgroup{
            display:flex;
            flex-direction: column;
            margin-bottom: 6px;
            >label{margin-bottom: 2px;}
            >input{
              padding: 3px;
            }
          }
        }
      }
      .adm-lps-option-sorting{
        display: flex;
        flex-direction: column;
        >span {
          font-weight: bolder;
          font-size: 1.1em;
          margin-bottom: $m1;
        }
      }
    }
  }
}


@media only screen and (max-width: $bp-xxl) {}

@media only screen and (max-width: $bp-xl) {
      #usermanagement-tabpanel-users {
        overflow: scroll;
        width: 100%;
        table {
      
          a>span,
          button>span {
            display: none;
          }
        }
      }
      #administration-tabpanel-users {
        overflow: scroll;
      }
}

@media only screen and (max-width: $bp-lg),
screen and (max-width: $bp-lg-retina) and (-webkit-min-device-pixel-ratio: 2) {

}

@media only screen and (max-width: $bp-md) {

}

@media only screen and (max-width: $bp-sm) {}

@media only screen and (max-width: $bp-xs) {}