#landing-page-footer{
  background-color: $grey-verylight;
  display: flex;
  justify-content:center;
  font-size: 1rem;
  >a{
    font-size: .66em;
    text-transform: uppercase;
    text-decoration: none;
    color: $grey6C;
    padding: 5em 3em;
    transition: background-color 2s ease;
    background-color: rgba(0,0,0,0);
    &:hover{
      transition: background-color .5s ease;
      background-color: rgba(0,0,0,.05);
    }
  }
}
#landing-page-upsell{
  .product-list-wrapper{
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  }
}
#landing-page-gallerynav{
  display: grid;
  >a {
    text-decoration: none;
    color: $font-color;
    &:hover{
      color:inherit;
    }
    >span{
      margin-top: $gap;
    }
    >.gallerynav-image-wrapper{
      position: relative;
      &::before{
        content: "";
        display: block;
        padding-top: 62.5%;
        width: 100%;
      }
      >img{
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
#landing-page-news{
  margin-top: $gap;
  &.events-wrapper{
    grid-template-columns: 1fr 1fr 1fr;
    >a{
      height: 30em;
      max-height: 100vh;
      max-width: 100%;
    }
  }
}

//md
@media only screen and (max-width: $bp-md) {
  #landing-page-news {  
      &.events-wrapper {
        grid-template-columns: 1fr;
      }
    }
}
