#dashboard-grid{
  display: grid;
  background-color: transparent;
  padding: 0;
  grid-template-columns: 13fr 13fr 13fr 13fr 17fr;
  grid-template-rows: auto auto auto auto;
  gap: $gap $gap;
  grid-template-areas:
    "greeting shoppingCarts events events newsletter"
    "upSell upSell events events directOrder"
    "upSell upSell events events listImport"
    "lastOrders orderHistory orderHistory orderHistory contact";

  > div:not(.clear) {
    background-color: $background-tile;
    padding: $gap-sm;
  }

  .lastOrders { grid-area: lastOrders;
    table{
      tr{
        td{
          &:first-child{
            padding-left: 0;
          }
          &:last-child{
            padding-right: 0;
          }
        }
      }
    }
   }
  .orderHistory { grid-area: orderHistory; }
  .contact { grid-area: contact; }
  .listImport { grid-area: listImport; }
  .directOrder { grid-area: directOrder; }
  .upSell { grid-area: upSell; }
  .events { grid-area: events; }
  .greeting { grid-area: greeting; }
  .shoppingCarts { grid-area: shoppingCarts; }
  .newsletter { grid-area: newsletter; }

}
.contact-item{
  .contact-item-atts{
    >span{
      text-transform: capitalize;
    }
    >a{
      text-transform: none;
      color: inherit;
    }
  }
  .contact-item-img-wrapper{
    position: relative;
    &::before{
      content:"";
      display: block;
      position: relative;
      width: 100%;
      padding-top:100%;
    }
    .contact-item-img{
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius:100px;
      width: 100%;
      height: 100%;
      top:0;
      left: 0;
      color:$font-color;

      >img{
        border-radius: inherit;
        width: 100%;
        height: 100%;
        object-fit: contain;
        background: $white;
      }
      &.contact-item-img-fallback{
        >img{
          padding: 10px;
        }
      }
    }
  }
}

.product-upsell-grid{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: $gap $gap;
  height: 100%;
}

.order-timetable-grid{
  height: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto auto auto auto auto auto;
  gap: 0px $gap; 
  grid-template-areas:
    "i1 t1"
    "s1 ."
    "i2 t2"
    "s2 ."
    "i3 t3"
    "s3 ."
    "i4 t4"
    "s4 ."
    "i5 t5";
  .i1 { grid-area: i1; }
  .i2 { grid-area: i2; }
  .i3 { grid-area: i3; }
  .i4 { grid-area: i4; }
  .i5 { grid-area: i5; }
  .t1 { grid-area: t1; }
  .t2 { grid-area: t2; }
  .t3 { grid-area: t3; }
  .t4 { grid-area: t4; }
  .t5 { grid-area: t5; }
  .s1 { grid-area: s1; }
  .s2 { grid-area: s2; }
  .s3 { grid-area: s3; }
  .s4 { grid-area: s4; }
  >div{
    display: flex;
    align-items: center;
  }
  >.spacer {
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: .3em 0;
    >span{
      line-height: 0.9em;
    }
  }
  >.icon-inactive{
    box-shadow: 0 0 0 1px $grey-light inset;
    border-radius: 10em;
    height: 1.5em;
  }
}

.orderHistory {
   .order-history-widget-row {
     p.clipped-paragraph {
      white-space: nowrap;
      width: 7rem;
      overflow: hidden;
      text-overflow: ellipsis;
     }
     .order-history-widget-row-btns{
        display: grid;
        grid-template-columns: 32px 32px 32px;
        grid-template-rows: 32px;
        gap: $gap-sm;
        grid-template-areas:
          "order-confirmation order-invoice order-reorder";
        justify-content: center;
        .order-confirmation{grid-area: order-confirmation;}
        .order-invoice{grid-area: order-invoice;}
        .order-reorder{grid-area: order-reorder;}
     }
   }
}

#userAccount-component-wrapper {
  .userrole {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: .1em;
    padding: .2em;
    &:hover {
      background: $grey-verylight;
    }
    &.active{ color: $color-brand; }
    &.inactive{ color: $mui-red; }
  }
}

@media only screen and (max-width: calc($bp-xl - 200px)) {
  #dashboard-grid {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto auto auto auto auto auto auto;
    grid-template-areas: "greeting shoppingCarts newsletter"
      "upSell upSell directOrder"
      "upSell upSell listImport"
      "events events contact"
      "events events contact"
      "lastOrders lastOrders lastOrders"
      "orderHistory orderHistory orderHistory"
    ;
  }
}

@media only screen and (max-width: $bp-lg),
screen and (max-width: $bp-lg-retina) and (-webkit-min-device-pixel-ratio: 2) {
  #dashboard-grid {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto auto auto auto auto auto auto;
    grid-template-areas: "greeting shoppingCarts newsletter"
      "upSell upSell directOrder"
      "upSell upSell listImport"
      "events events contact"
      "events events contact"
      "lastOrders lastOrders lastOrders"
      "orderHistory orderHistory orderHistory"
    ;
  }
}
@media only screen and (max-width: $bp-md) {
  .product-upsell-grid {
    gap: $gap-sm
  }
  #dashboard-grid {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto 20em auto 20em auto auto;
    gap: $gap-sm;
    grid-template-areas:
      "greeting greeting"
      "shoppingCarts newsletter"
      "upSell upSell"
      "directOrder listImport"
      "events events"
      "lastOrders contact"
      "orderHistory orderHistory"
    ;
    >.events:not(.clear){ padding: 0;}
    .orderHistory{
      max-width: calc(100vw - (2 * $gap-sm));
      
      .orderHistory-table-wrapper{
        width: 100%;
        overflow: scroll;
        box-shadow: 0 $gap-sm $gap-sm $gap-sm rgba(0,0,0,.1) inset, $gap-sm 0 $gap-sm $gap-sm rgba(0, 0, 0, .1) inset;
      }
    }
  }
}
@media only screen and (max-width: $bp-sm) {
    .product-upsell-grid {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;
    }
  #dashboard-grid {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto 100vh auto auto 100vh auto auto auto;
    gap: $gap-sm;
    grid-template-areas:
      "greeting"
      "shoppingCarts"
      "newsletter"
      "upSell"
      "directOrder"
      "listImport"
      "events"
      "contact"
      "lastOrders"
      "orderHistory"
    ;
  }
}