@font-face {
    font-family: FuturaBd;
    src: url(../assets/fonts/bba6bebc-758c-4958-be5c-2d2daf126b56.eot);
    src: url(../assets/fonts/bba6bebc-758c-4958-be5c-2d2daf126b56.eot.eot?#iefix) format('embedded-opentype'),url(../assets/fonts/3099aa0c-d988-466b-9aa4-e94ddc71c81a.woff) format('woff'),url(../assets/fonts/52084199-11aa-44aa-8b5a-be3f020c44ef.ttf) format('truetype')
}
@font-face {
    font-family: FuturaBk;
    src: url(../assets/fonts/035cba35-4fdc-49e8-be11-5bc8cd5b402a.eot);
    src: url(../assets/fonts/035cba35-4fdc-49e8-be11-5bc8cd5b402a.eot.eot?#iefix) format('embedded-opentype'),url(../assets/fonts/7a6b64dd-1fb8-4de0-810d-df26a6c67b03.woff) format('woff'),url(../assets/fonts/fb6fff71-86e4-4bb3-9751-d9d387471ba8.ttf) format('truetype')
}
@font-face {
    font-family: FuturaLt;
    src: url(../assets/fonts/3cd1721e-4a46-4eb5-bc5e-af79094babc1.eot);
    src: url(../assets/fonts/3cd1721e-4a46-4eb5-bc5e-af79094babc1.eot.eot?#iefix) format('embedded-opentype'),url(../assets/fonts/3999080d-6014-4742-ac4e-1b472a48d303.woff) format('woff'),url(../assets/fonts/586c5d53-4f7f-4c0e-9e87-da6184893c59.ttf) format('truetype')
}