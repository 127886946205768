.addresses-input-grid{
   display: grid; 
  grid-template-columns: 1fr 1fr 0.25fr .75fr; 
  //grid-template-rows: 1fr 1fr 1fr 1fr; 
  grid-template-rows: 1fr 1fr 1fr; 
  gap: $gap-sm $gap-sm; 
  grid-template-areas: 
    //"addr-type . . ."
    "addr-companyName addr-street addr-zip addr-city"
    "addr-companyName2 addr-street2 addr-country addr-country"
    ". . addr-submit addr-submit"; 

    .addr-type { grid-area: addr-type; }
    .addr-companyName { grid-area: addr-companyName; }
    .addr-companyName2 { grid-area: addr-companyName2; }
    .addr-street { grid-area: addr-street; }
    .addr-street2 { grid-area: addr-street2; }
    .addr-zip { grid-area: addr-zip; }
    .addr-city { grid-area: addr-city; }
    .addr-country { grid-area: addr-country; }
    .addr-submit { grid-area: addr-submit; }
}


  .user-addresses-list, .ordering-options-list{
    display: flex;
    flex-wrap: wrap;
    .user-address-item{
      display: flex;
      flex-direction: column;
      border: 1px solid $border-color;
      input{
        display: none
      };
      &.active{
        border-color: $color-brand;
        box-shadow: 0 0 0 1px $color-brand inset;
      }
      >div,>label{
        padding: $gap-sm;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;
        cursor: pointer;
      }
      .user-address-segment{
        display: flex ;
        flex-direction: column;
        margin-bottom: .1rem;
        &:last-child{
          margin-bottom: 0;
        }
      }
      &:last-child{
        margin-right: 0;
      }
    }
    &.user-addresses-list--summary{
      >.user-address-item{
        border: none;
        //padding: 0 $gap-sm;
        >.user-address-segment{
          margin-bottom: .3em;
          padding: 0;
      }
      }

    }
  }
  .order-summary-sidebar-text{
    >span{
        font-size: 1.15em;
        font-weight: bold;
        letter-spacing: 1px;
    }
  }


@media only screen and (max-width: $bp-md) {
  .addresses-input-grid{
    grid-template-columns: 1fr 0.25fr 0.75fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-gap: 1.236rem 1.236rem;
    gap: 1.236rem 1.236rem;
    grid-template-areas: 
      "addr-companyName addr-companyName2 addr-companyName2"
      "addr-street addr-zip addr-city"
      "addr-street2 addr-country addr-country"
      ". addr-submit addr-submit";
  }

}
@media only screen and (max-width: $bp-sm) {
  .addresses-input-grid{
    grid-template-columns: 0.25fr 0.75fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 1.236rem 1.236rem;
    gap: 1.236rem 1.236rem;
    grid-template-areas: 
      "addr-companyName addr-companyName"
      "addr-companyName2 addr-companyName2"
      "addr-street addr-street"
      "addr-street2 addr-street2"
      "addr-zip addr-city"
      "addr-country addr-country"
      "addr-submit addr-submit";
  }

}