#side-nav {
  position: relative;
  font-size: 1rem;
  margin-top: $gap;

  > ul {
    background-color: $background-tile;
    display: flex;
    flex-direction: column;
    list-style-type: none;
  }

  li {
    border-left: 3px solid transparent;
    width: 8em;
    transition: all .5s ease;

    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 1em .5em;
      width: 100%;
      height: 100%;
      text-decoration: none;
      font-size: .75rem;
      line-height: 1.3em;
      color: $font-color;
      text-align: center;

      .side-nav-icon-wrapper {
        display: flex;
        justify-content: center;
        width: 2em;
        height: 2em;
        margin-bottom: .2em;
      }
    }
    &:hover{
      background-color: rgba(0,0,0,.1);
    }
    &.active {
      border-color: $black;
      background-color: $color-brand;
      &:hover{
        background-color: $color-brand-darker;
      }
      a {
        color: $font-color-light;
      }
    }

    &.divider-top {
      position: relative;
      margin-top: .5em;

      &::before {
        position: absolute;
        content: "";
        padding: .25em 1em;
        background-clip: content-box;
        top: -.5em;
        left: 0;
        right: 0;
        box-sizing: content-box;
        height: 1px;
        background-color: $font-color;
        display: block;
      }
    }
  }
}

.sidenav-component-wrapper {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto;
  grid-template-areas: "sidenav content";
  width: 100%;
  #side-nav{
    grid-area: sidenav;
  }
  .sidenav-content {
    grid-area: content;
    margin: $gap;
    font-size: 1rem;
    flex-grow: 1;
    background-color: $background-tile;
    padding: $gap-sm $gap;
    max-width: 100%;
    overflow: scroll;

    .nav-link {
      cursor: pointer;
    }
  }

  .fav {
    background-color: #EFEFEF;
  }

}

@media only screen and (max-width: $bp-md) {
  .sidenav-component-wrapper {
      grid-template-columns: 1fr;
      grid-template-rows: auto auto;
      grid-template-areas: "sidenav" "content";
    >.sidenav-content{
      margin: $gap-sm;
      padding: $gap-sm;
    }
  }
  #side-nav{
    margin-top: $gap-sm;
    >ul{
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-evenly;
      >li{
        width: 6em;
        border: none;

        &.active{

        }
      }
    }
  }
}
@media only screen and (max-width: $bp-xs) {
  .sidenav-component-wrapper {
    >.sidenav-content{
      //margin: $gap-sm 0;
    }
  }
}