#product-view-table-list, #product-view-table-cross{
  position: relative;
  /* colgroup{
    overflow: hidden;
    .table-cross-column{
      &.table-cross-column-hasvalue{
          background-color: blue !important;
        &:hover{  
          background-color: red !important;
        }
      }
    }
  } */
  >table{
    width:100%
  }

  thead {
    th.table-list-header-item {
      background-color: $grey6C;
      color: $white;
      top:0;
      z-index: 1;
      position: sticky;
      vertical-align: middle;
      text-align: center;
      border: none;
      border-right: 2px solid $grey;
      font-family: $font-family-bold;
      font-weight: normal;
      padding: $m1;
      background-clip: padding-box;
      &:last-child{
        border-right: none;
      }
      &#table-list-header-item--ordernr {
        min-width: 15em;
      }
      .table-header-filter{
        background-color: $white;
        color: $font-color;
        border-radius: 0;
        border: none;
        padding: .2em;
        font-family: $font-family;
        font-weight: bold;
        margin-left: $m1;
        >option{
          font-family: $font-family;
        }
      }
      .table-list-header-item-label{
        display: flex;
        flex-direction: column;
      }
    }
  }
  tbody{
    tr{
      &.product-table-list-item,&.product-table-cross-item {
        font-weight: bold;
        
        >td{
          border:none;
          text-align: center;
          border-right: 2px solid $grey-light;
          padding: .5rem;
          position: relative;
          vertical-align: middle;
          &:last-child{
            border-right: none;
          }
          
        }

      }
      .product-table-list-item-price{
        &.product-table-list-item-price-deal{
          background-color: rgba($color: $color-brand, $alpha: .25);
        }
        .product-table-list-item-saletag{
          position: absolute;
          background-color: #00949C;
          padding: 0.25em 1.2rem;
          right: 0;
          top: 0;
          bottom: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          color:$white;
          font-size: 1.5em;
          transform: translateX(50%);

        }
      }
      &.active{
        background-color: $color-brand !important;
        color: $white;
        >td{
          border-right: 2px solid $grey;
        }
      }
      &.product-table-list-item{
        &:nth-child(4n-1){
          background-color: $grey-verylight; 
        }
        &:hover{
          background-color: rgba($color: $color-brand, $alpha: .25);
        }
        >.product-table-list-item-orderdimension{
          background-color: rgba($color: $color-brand, $alpha: .05);
        }
      }
      &.product-table-cross-item{
        position: relative;
        z-index: 1;

        >td{
          vertical-align: middle;
          position: relative;
          &.table-cross-cell-inactive{
            background-color: rgba($color: $grey, $alpha: .1);
            color: $color-brand;
            border-right: 2px solid $grey;
            background-clip: padding-box;
            padding: $m1 $m1;
          }
          &.table-cross-cell-active{
            padding: 0;
            >button{
              font-weight: bold;
            }
            &:hover{
             /*  &::before{
                content: "";
                background-color: rgba($color: $color-brand, $alpha: .25); 
                //height: 10000px;
                height: 200%;
                left: 0;
                position: absolute;
                top: -5000px;
                width: 100%;
                z-index: -1;
                pointer-events: none;
              } */
            }
            &.cross-hover{
                background-color: rgba($color: $color-brand, $alpha: .25); 
            }
          }
          &.table-cross-cell-orderable{
            font-weight: normal;
            font-family: $font-family-bold;
            background-color: rgba($color: $grey, $alpha: .4);
          }
          >.table-cross-deal{
              position:absolute;
              top:0;
              height: 100%;
              right: 0;
              padding: .1em .5em;
              color: $white;
              background-color: $color-brand;
          }
        }
        &:nth-child(2n-1){
          background-color: rgba($color: $grey-light, $alpha: .3); 
          //background-clip: content-box;
          &.table-cross-cell-inactive{
            background-color: rgba($color: $grey, $alpha: .4);
            border-right: 2px solid $grey;
            background-clip: padding-box;
          }
        }
        &:hover{
          z-index: 2;
          background-color: rgba($color: $color-brand, $alpha: .25); 
        }
      }
      &.product-table-list-collapse{
        >td {
          border: none;
        }     
        /* &.collapse--single, &.collapse--1, &.collapse--2{
            transition: all 1s linear;
            transform-origin: top;
            overflow: hidden;
          &.active{
            //transform: scaleY(1);
          }
          &.inactive{
           // transform: scaleY(0);
            transform: skewY(.5);
          }
        }
        &.collapse--single{}
        &.collapse--1{}
        &.collapse--2{} */
      }
    }
  }
}
#product-view-table-cross{
  /* overflow: auto;
  max-height: 100vh;
  max-width: 100vw; */
    thead {
      position: relative;
      z-index: 7;
    }
}



.product-table-list-util {
  max-height: 0;
 /*  &.product-table-list-util--0 {
    &.active{}
  }
  &.product-table-list-util--1 {
    &.active{}

  } */
  &.active{
    max-height: 2em;
  }
}


@media only screen and (max-width: $bp-md) {
  #product-view-table-list {
    overflow-x: scroll;
  }

  #product-view-table-list,
  #product-view-table-cross {
    thead {
      >tr {
        >th.th-normal{
          display: none;
        }
      }
    }
    tbody {
      >tr {
        >td.td-normal {
          display: none;
        }
      }
    }
  }
}