#product-category-page-grid,#search-results-page-grid {
  padding: $gap;
  flex-grow: 1;
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-template-rows: 100%;
  gap: $gap $gap;
  grid-template-areas:
    "pc-filter pc-content";

    .product-category-page-filter, #search-results-page-sidebar{
      padding: $gap-sm $gap;
      grid-area: "pc-filter";
      background-color: $background-tile;
      >.product-category-page-filter-inner{
        display:grid;
        grid-template-columns: 1fr;
        grid-auto-rows: auto;
        grid-gap: $gap-sm;
        >.collapse-group-wrapper{
          margin: 0;
        }
      }
      >.search-results-succestion-wrapper{
        margin-top: $gap-sm;
        >.search-results-category-suggestion{
          margin: $m1 0;
          .search-results-category-suggestion-name{
            margin-right: .5em;
          }
          span{
            font-size: 1.1rem;
          }
          &:first-child{
            margin-top: 0;
          }
        }
      }
      .product-category-filter-slider {
        border: none;
        margin: 0 auto;
        padding: 1em 0;
        width: 28em;

        &__bar {
          width: 17em;
          margin: 0 0 0 1em;
          padding: 0;
          float: left;
          overflow: hidden;
        }
        &__item {
          display: block;
          position: relative;
          float:left;
          width: 20%;
          height: 2.5em;
        }
        input[type="radio"] {
          display: block;
          margin: -1em auto .5em;
          position: relative;
          &:checked:focus ~ label:after {
            border-color: darken(#dcdcdc, 10);
            box-shadow: 0 0 3px lighten(#007fae, 40);
          }
        }
        label {
          display: block;
          font-size: .75em;
          line-height: 1.5em;
          height: 4em;
          text-align: center;
          cursor: pointer;
          
        }
        input[type="radio"]:checked ~ label {
            z-index: -1;
          &:before {
            content: '';
            display: block;
            height:.75em;
            width:41em;
            border:0px solid $grey-light;
            border-right-width: 20.5em;
            border-left-width: 0;
            box-shadow: 0 0 0 1px #dcdcdc;
            position: absolute;
            margin-left: -18.25em;
            top: 2.125em;
            background-color: $color-brand;
          }
          &:after {
            content: '';
            display: block;
            position: absolute;
            height: 1.75em;
            width: 1.75em;
            border: 1px solid #dcdcdc;
            border-radius: 50%;
            top: 1.75em;
            left: 1.25em;
            background-color: $grey6C;
          }
        }
      }
      
    }
    #product-category-page-content,#search-results-page-content{
      padding: $gap-sm $gap;
      grid-area: "pc-content"; 
      background-color: $background-tile;
    }
}
.product-view-table-utils{
  display: flex;
  justify-content: end;
  padding-bottom: $m1;
}



@media only screen and (max-width: $bp-xxl) { 
  #product-category-page-grid{
    .product-category-page-filter {
      .product-category-page-filter-inner {
        .collapse-group-wrapper {
          .formOptionsGrid {
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
  }

}
@media only screen and (max-width: $bp-lg),
screen and (max-width: $bp-lg-retina) and (-webkit-min-device-pixel-ratio: 2) { 
  #product-category-page-grid{
    display: block;
    .product-category-page-filter{
      display: none;
    }
  }
  #search-results-page-grid{
    display: block;
    #search-results-page-sidebar{
      display: none;
    }
  }

}
@media only screen and (max-width: $bp-md) {
  #product-category-page-grid {
    padding: $gap-sm;
    >#product-category-page-content{
      padding: $gap-sm;
    }
  }
  #search-results-page-grid{
    padding: $gap-sm;
    #search-results-page-content {
      padding: $gap-sm;
    }
  }
}
@media only screen and (max-width: $bp-sm) {
  #product-category-page-grid {
    padding: $m1;

    >#product-category-page-content {
      padding: $m1;
    }
  }

  #search-results-page-grid {
    padding: $m1;

    #search-results-page-content {
      padding: $m1;
    }
  }
}
@media only screen and (max-width: $bp-xs) {
  .product-view-table-utils {
    padding: $m1 $gap-sm;
    background-color: $grey-verylight;
    .custom-switch {
      padding-left: 0;
    }
  }

}