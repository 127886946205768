@import "./fonts";


//Variables - Colors

$color-brand: #00949C;
$color-brand-darker: #007C82;
$white: #fff;
$black: #000;
$grey: #a7a7a7;
$grey-light: #d9d9d9;
$grey-verylight: #f8f8f8;
$grey6C: #6C6C74;
$background: #EFEFEF; //#00000029 / rgba(0,0,0,0.16)
$background-bright: #fafafa;
$background-tile: #fff;
$font-color: #333;
$font-color-light: #fff;
$font-color-darker: #222;
$border-color: #C3C3C3;
$table-vertical: #A3A3A3;
$utility-nav-color: #4C4C4C;
$mui-red: #d32f2f;


//Variables - Font

$font-family: "FuturaBk";
$font-family-light: "FuturaLt";
$font-family-bold: "FuturaBd";

//Variables - Spacing
$gap: 2rem; //The default padding/margin size
$gap-sm: 1.236rem; //The smaller version of $gap based on the golden-ratio
$m1: .75rem;
$m2: 1rem;
$m3: 1.5rem;
$m4: 2rem;
$m5: 2.5rem;
$m6: 3.5rem;

//Variables - Breakpoints
$bp-xxl: 1919px;
$bp-xl: 1479px;
$bp-lg: 1080px;
$bp-lg-retina:1366px;
$bp-md:920px;
$bp-sm:640px;
$bp-xs:440px;

//Variables - misc
$box-shadow1: 0 0 .5em .1em rgba(0,0,0,.1);
$box-shadow1-down: 0 .5em .5em .1em rgba(0,0,0,.1);
$mobile-header-height: 54px;

@import "./browserreset";
@import "bootstrap/dist/css/bootstrap.min.css";
@import "./bootstrap-overwrite";
@import "./material-ui-overwrite";

html {
  overflow-x: hidden;
  width: 100vw;
  font-family: Verdana;
  height: 100%;
  body {
    height: 100%;
    font-family: $font-family;
    background-color: $background;

    @import "./components/base";
    @import "./components/carousel";
    @import "./components/appheader";
    @import "./components/dashboard";
    @import "./components/landingPage";
    @import "./components/productCategory";
    @import "./components/productView";
    @import "./components/sidenav";
    @import "./components/signin";
    @import "./components/menu";
    @import "./components/productTable";
    @import "./components/cart";
    @import "./components/addresses";
    @import "./components/ordering";
    @import "./components/resetPassword";
    @import "./components/administration";

    #knr-app{
      height: 100%;
    }
    #App{
      display: flex;
      flex-direction: column;
      height: 100%;
      #App-body{
        min-height: 0;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        #content{
          min-height: 0;
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          justify-content: stretch;
          align-items: stretch;
          #easyKatView-component-wrapper{
            display: flex;
            flex-direction: column;
            height: 100%;
            min-height: 0;
          }
        }
      }
      .divider { margin: 0 $m2; }
        .divider-primary { border-top: 1px solid $color-brand; }
        .divider-secondary { border-top: 1px solid $border-color; }
        .knr-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          text-decoration: none;
          border: none;
          text-transform: uppercase;
          text-align: center;
          // font-weight: 700;
          // font-size: 1.43em;
          // line-height: 1.35em;
          &:focus {
            text-decoration: none;
            outline: none;
            border: none;
            color: inherit;
          }
          &.primary {
            background-color: $color-brand;
            color: #fff;
            padding: $m1 $m3;
            border: none;
          }
          &.white {
            color: $font-color;
            background-color: $white;
            padding: $m1 $m3;
            border: none;
            // &:hover {}
          }
        }

        .dropdown {
          font-weight: inherit;
          color: inherit;

          button {
            font-weight: inherit;
            text-transform: uppercase;
            svg {
              margin-right: $m2;
            }
          }
          .dropdown-menu {
            background-color: $utility-nav-color;
            padding: 0;
            max-height: 12rem; // ToDo define
            overflow-y: scroll;

            .dropdown-item-text {
              padding: 1.5rem;
              color: $white;
              text-transform: uppercase;
            }

          }
        }
    }
  }
}
*{
  scrollbar-color: rgba(0,0,0,.2) rgba(0,0,0,.05);
  scrollbar-width: thin;
  &>:hover{
    scrollbar-color: $color-brand rgba(0,0,0,.05);
  }
  ::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  }
  ::-webkit-scrollbar-thumb {
  background:rgba(0,0,0,.2);
  }
  ::-webkit-scrollbar-thumb:hover{
  background: $color-brand;
  }
  ::-webkit-scrollbar-track{
  background: rgba(0,0,0,.05);
  }
}

@media only screen and (max-width: $bp-lg),
screen and (max-width: $bp-lg-retina) and (-webkit-min-device-pixel-ratio: 2) {
  #App{
    #App-body {
      margin-top: $mobile-header-height;
    }
  }
}
@media (max-width: $bp-md) {
  html>body{
    padding-bottom: 4em;
  }
}

@import "./print";
