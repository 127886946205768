
#shopping-cart-print {
    position: relative;
    display: none;
    background-color: #fff;
    padding-left: 1cm;
    z-index: 9999;
    min-width: 21cm;
    min-height: 29.7cm;

    #sc-print-positions{
        margin-top: 1.5cm;
        margin-bottom: 1cm;
        width: 100%;
        table {
            width: 100%;
            thead{
                tr{
                    th{
                        padding-right:.25cm;
                        padding-bottom:.125cm;
                        border-bottom: .5mm solid $border-color;
                        vertical-align: middle;
                        &:last-child{
                            padding-right: 0;
                        }
                    }
                }

            }
            tbody{
                tr{
                    td{
                        padding-right:.25cm;
                        padding-top: .125cm;
                        padding-bottom: .125cm;
                        vertical-align: top;
                        border-bottom: .5mm solid $border-color;
                        &:last-child{
                            padding-right: 0;
                            min-width: 2cm;
                        }
                        >.product-list-item-image {

                                width: 1.5cm !important;
                                height: 1cm !important;

                            img{
                                width:100%;
                                object-fit: contain;
                            }
                        }
                    }
                    &:last-child{
                        td{
                            border:none;
                        }
                    }
                    &.sc-print-subpos{
                        >td{
                            border-bottom: .5mm dashed $border-color;

                        }
                        &.sc-print-subpos-last{
                            >td{
                                border-bottom: 1mm solid $border-color;
                            }
                        }
                        
                    }
                }
            }
        }
    }
    #sc-print-footer{
        display: grid;
        grid-template-columns: 1fr 1fr 1.5fr;
        grid-template-rows: auto;
        grid-template-areas: "comment ordernr price";
        border-top: .125cm solid $color-brand;
        width: 100%;
        
        #sc-print-footer-comment {
            grid-area: comment;
        }
        
        #sc-print-footer-ordernr {
            grid-area: ordernr;
        }
        table#sc-print-footer-price{
            tbody{
                tr{
                    td{
                        text-align: right;
                        padding-top: .2cm;
                        &:first-child{
                            padding-right: 1cm;
                        }
                    }
                }
            }
        }
    }
}



@media print {
  html{
    min-width: 21cm;
    min-height: 29.7cm;
    #App-header,
    #side-shopping-cart,
    #order-nav,
    #order-confirmation-wrapper,
    #cart-sidebar,
    .ordering-page-main,
    .ordering-page-footer,
    .cart-pos-clear-wrapper,
    .order-sidebar-summary,
    .cart-sidebar-actions,
    .cart-sidebar-btn-list,
    .d-md-none,
    textarea{
      display: none !important;
    }
    .ordering-page-grid{
        display: block;
    }
    #shopping-cart-print{
      display: block !important;
    }
  } 
}