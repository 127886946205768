header {
    font-size: 1em;

    &#App-header {
        z-index: 20;
        #App-utility-navigation {
            background-color: $utility-nav-color;
            color: $white;
            padding: 0 $m5;
            align-items: center;
            //font-weight: 600;
            line-height: 1.25;
            font-size: 1rem;
            .App-header-user-info{
                .user-login-name {
                    color: $white;
                }

                .user-login-text {
                    color: $white;
                }

                .user-logout-text {
                    color: $white;
                }
            }

            #utility-navigation-left {
                flex: 1 1 50%;
                font-weight: inherit;
                color: inherit;

                >div {
                    display: flex;
                    justify-content: space-between;

                    &:not(:last-child) {
                        margin-right: $m3;
                    }

                    a {
                        display: flex;
                        align-items: center;
                        color: inherit;
                        font-weight: inherit;

                        &:not(:nth-child(3)) {
                            text-transform: uppercase;
                        }

                        &:not(:last-child) {
                            margin-right: $m3;
                        }
                    }
                }
            }

            #utility-navigation-right {
                flex: 1 1 50%;
                justify-content: flex-end;
                font-weight: inherit;
                color: inherit;

                /*                 >.dropdown {
                    margin-right: $m4;
                } */
                >div:nth-child(2) {
                    a {
                        //margin-right: $m2;
                        padding-right: 0;
                        font-weight: inherit;
                        color: inherit;

                        svg {
                            margin-right: $gap-sm;
                        }
                    }
                }
            }

            .app-language-selector {
                margin-right: $gap-sm;

                >.MuiFormControl-root {
                    margin: 0 !important;

                    >.MuiFilledInput-root {
                        color: $white;
                        background: none;
                        border: none;

                        >.MuiSelect-filled {
                            padding-top: .5em;
                            padding-bottom: .5em;
                        }
                    }
                }

            }

            .language-dropdown-item {
                cursor: pointer;
                transition: background-color .3s ease;

                &:hover {
                    background-color: rgba(0, 0, 0, .25);
                }
            }
        }

        #App-header-main {
            display: grid;
            grid-template-rows: 1fr;
            grid-template-columns: auto 1fr auto auto;
            grid-template-areas: "logo nav search cart";
            padding: 0 $gap;
            grid-gap: $gap-sm $gap;
            background-color: $white;
            align-items: center;

            .App-header-logo {
                grid-area: logo;
                padding: $gap 0;
            }

            .App-header-navigation {
                grid-area: nav;
                width: 100%;
                justify-content: center;
                grid-area: nav;

                // search modal
                position: relative;
                font-size: 1.5rem;
                line-height: 1.3888;
                padding: $gap 0;

                .App-header-nav-list {
                    display: flex;
                    justify-content: flex-start;
                    flex-wrap: wrap;

                    >.App-header-nav-item {
                        display: flex;
                        align-items: center;

                        >a {
                            text-transform: uppercase;
                        }

                        .App-header-nav-item-icon {
                            height: 1.8em;
                            max-width: 2.5em;
                            margin-right: .5em;
                            transition: transform .3s ease;
                            object-fit: contain;
                        }

                        // rem > em
                        font-size: 1.3rem;
                        padding: .75rem 1rem;
                        text-decoration: none;
                        position: relative;

                        &:hover {
                            .App-header-nav-item-icon {
                                transform: scale(1.25);
                            }
                        }

                        &.dropdown {
                            a {
                                text-decoration: none;
                                color: initial;
                                font-weight: bold;
                                letter-spacing: 1px;
                            }

                            .dropdown-content-wrapper {
                                display: none;
                                position: absolute;
                                left: 0;
                                z-index: 1000;
                                width: auto;
                                min-width: 100%;
                                padding: $gap-sm 0;
                                background: $white;

                                .dropdown-content {

                                    position: static;
                                    display: flex !important;
                                    flex-direction: column;
                                    font-size: inherit;
                                    padding: .5rem $gap-sm;

                                    >a {
                                        text-decoration: none;
                                        border-bottom: 3px solid transparent;
                                        padding-bottom: .1rem;
                                    }

                                    p {
                                        white-space: normal;
                                    }

                                    &:first-child {
                                        padding-top: 0;
                                    }

                                    &:last-child {
                                        padding-bottom: 0;
                                    }

                                    &:hover>a {
                                        border-bottom-color: $color-brand;
                                    }
                                }
                            }

                            &:hover {
                                box-shadow: $box-shadow1-down;
                                // border-bottom: 3px solid #00949C;

                                >.mainCategory {
                                    text-decoration: none;
                                    color: initial;
                                }

                                .dropdown-content-wrapper {
                                    display: flex;
                                    flex-direction: column;
                                    box-shadow: $box-shadow1-down;
                                    top: 100%;
                                    border-top: 3px solid #00949C;

                                    &::before {
                                        content: '';
                                        width: 5%;
                                        height: 5%;
                                        position: absolute;
                                        left: 0;
                                        top: -.5rem;
                                        background-color: #fff;
                                    }

                                    &::after {
                                        content: '';
                                        width: 5%;
                                        height: 5%;
                                        position: absolute;
                                        right: 0;
                                        top: -.5rem;
                                        background-color: #fff;
                                    }

                                    .dropdown-content {
                                        display: block;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            #App-mobile-menu-btn {
                grid-area: nav;
                display: none;
                cursor: pointer;
            }

            .App-header-search {
                grid-area: search;
                height: 100%;
                display: flex;
                align-items: center;

                .panel {
                    height: 100%;
                    display: flex;
                    align-items: center;

                    .input-wrapper {
                        display: flex;
                        flex-shrink: 0;

                        &.search {
                            padding: 0;
                            width: 13rem;
                            align-items: center;
                            border-top: none;
                            border-left: none;
                            border-right: none;
                            border-bottom: 1px solid $border-color;

                            button {
                                &.search-btn {
                                    appearance: none;
                                    border: none;
                                    font-size: 1.2rem;
                                    background: transparent;
                                    position: relative;
                                    padding: $m1 $gap-sm;
                                    color: $grey6C;
                                    >span{
                                        font-size: 1.2rem;
                                    }
                                    &:focus {
                                        outline: none !important;
                                    }
                                }
                                svg {
                                    margin-right: $gap-sm;
                                    &.cart {
                                        position: absolute;
                                        margin-left: $m1;
                                    }
                                }
                            }

                        }
                    }
                }
            }
            .App-header-filter {
                display: none;
                grid-area: filter;
                height: 100%;
                align-items: center;

                .panel {
                    height: 100%;
                    display: flex;
                    align-items: center;

                    .input-wrapper {
                        display: flex;
                        flex-shrink: 0;

                        &.filter {
                            padding: 0;
                            align-items: center;

                            button {
                                &.filter-btn {
                                    appearance: none;
                                    border: none;
                                    font-size: 1.2rem;
                                    background: transparent;
                                    position: relative;
                                    padding: $m1 $gap-sm;
                                    color: $grey6C;
                                    >span{
                                        font-size: 1.2rem;
                                    }
                                    &:focus {
                                        outline: none !important;
                                    }
                                }
                            }

                        }
                    }
                }
            }

            .App-header-user {
                display: none;
                grid-area: user;
                >.App-header-user-info{
                    .user-login-name {
                        color:$white;
                    }
                    .user-login-text {
                        color:$white;
                    }
                    .user-logout-text {
                        color:$white;
                    }
                    
                }
            }
            .App-header-cart {
                grid-area: cart;
                height: 100%;
                display: flex;
                align-items: center;

                .panel {
                    height: 100%;
                    display: flex;
                    align-items: center;

                    .input-wrapper {
                        display: flex;
                        flex-shrink: 0;

                        &.cart-btn-wrapper {
                            height: 100%;
                            display: flex;
                            align-items: center;
                            padding: 0 $m3 0 $m2;

                            .cart-btn {
                                position: relative;
                                z-index: 10;

                                .cart-item-num-indicator {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    font-weight: normal;
                                    flex-shrink: 0;
                                    height: 20px;
                                    min-width: 20px;
                                    border-radius: 100px;
                                    background-color: $color-brand;
                                    position: absolute;
                                    color: $font-color-light;
                                    bottom: 1.5rem;
                                    left: 1.5rem;
                                    font-size: 1rem;
                                    padding: 0 .2em;

                                    span {
                                        font-size: inherit;
                                        line-height: inherit;
                                    }
                                }
                            }

                            &.active,
                            &:hover {
                                background-color: $color-brand;
                                transition-duration: 500ms;

                                .cart-item-num-indicator {
                                    background-color: $white;
                                    color: $color-brand;

                                    &~svg {
                                        fill: #fff;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .App-header-search-slidein {
                height: 100%;
                width: 100%;
                grid-area: 1 / 2 / 2 / 5;
                position: relative;
                left: 0;
                transform: rotateY(90deg);
                transform-origin: right;
                z-index: 10;

                &.active {
                    transform: rotateY(0);
                    transition: all .5s ease;
   /*                  .App-header-search-slidein-inner {
                        left: 0;
                    } */
                }

                &.inactive {
                    pointer-events: none;
/* 
                    .App-header-search-slidein-inner {
                        transition: all 0s linear;
                        left: 100%;
                    } */
                }

                .App-header-search-slidein-inner {
                    transition: all .5s ease;
                    z-index: 10;
                    position: absolute;
                    width: 100%;
                    background: linear-gradient(90deg, #FFFFFFD3 0%, #FFFFFF 24%, #FFFFFF 100%);

                    display: grid;
                    grid-template-columns: 4fr 1fr;
                    grid-template-rows: 100%;
                    grid-template-areas: "search-field close";
                    justify-items: center;
                    align-items: center;
                    font-size: inherit;
                    height: 100%;

                    .search-field-wrapper {
                        grid-area: search-field;

                        .input-wrapper {
                            input[type="text"] {
                                appearance: none;
                                border-top: none;
                                border-left: none;
                                border-right: none;
                                //border-bottom: 1px solid $color-brand;
                                background: transparent;
                                color: $grey6C;
                                width: 50rem;
                                font-size: inherit;
                                padding: $m1 $m3;

                                &:focus {
                                    outline: none !important;
                                }
                            }

                            button {
                                &.search {
                                    padding: $m1 $m3;
                                    background-color: $color-brand;
                                    color: $white;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    border: none;

                                    p {
                                        text-transform: uppercase;
                                        font-size: 1.3rem;
                                    }

                                    svg {
                                        margin-right: $m1;
                                    }
                                }
                            }
                        }
                    }

                    .search-close-btn-wrapper {
                        grid-area: close;
                    }
                }

                .menu-wrapper {

                    .panel {
                        align-items: center;
                        display: flex;
                        font-size: 1.17rem;
                        height: 100%;

                        h1,
                        h2,
                        h3,
                        h4,
                        h5,
                        h6,
                        title {
                            color: $color-brand;
                            font-weight: 700;
                            text-transform: capitalize;
                        }

                        h1,
                        h2,
                        h3,
                        h4,
                        h5,
                        h6 {
                            line-height: 1.3;
                        }

                        .title {
                            line-height: 1.35;
                        }

                        h3 {
                            font-size: 1.14em;
                        }

                        .inner {
                            font-size: inherit;
                            height: 100%;
                        }
                    }

                    &.search-menu {
                        position: absolute;
                        left: 0;
                        top: 0;
                        height: 100%;
                        width: 100%;
                        z-index: 100;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        //animation
                        transform: translateX(150%);

                    }
                }

            }
        }
        #App-mobile-menu-background {
            background-color: transparent;
            transition: background-color 1s ease;
            position: fixed;
            right:100%;
            top:0;
            bottom: 0;
            width:100%;
            z-index: -1;
            &.active {
                right: 0;
                background-color: rgba(0, 0, 0, .1);
            }
        }
        #App-mobile-menu {
            display: none;
            position: absolute;
            left: 0;
            transform: translateX(-100%);
            background-color: rgba(0, 0, 0, .4);
            z-index: 999;
            font-size: 1rem;
            transition: transform .3s ease;
            height: calc(100vh - ($mobile-header-height + 0px));
            &::before {
                content: "";
                display: block;
                position: absolute;
                pointer-events: none;
                top: 0;
                left: 0;
                width: 100%;
                box-shadow: 0 2em 2em -2em rgba(0, 0, 0, .5) inset;
                height: 2em;
            }
            &.active {
                transform: translateX(0);
            }
            h2{
                padding: $m1;
                background-color: $white;
            }

            .App-mobile-menu-wrapper {
                display: flex;
                flex-direction: column;
                padding: $gap-sm;
                overflow: scroll;
                max-height: calc(100vh - $mobile-header-height);
                #App-mobile-menu-header {
                    margin-bottom: $gap-sm;
                    display: flex;
                    justify-content: space-between;
                    gap: $gap-sm;

                    >.App-mobile-menu-header-backbtn {
                        padding: $gap-sm;
                        border: none;
                        background-color: $white;
                    }
                    >.App-header-search-slidein {
                        flex-grow: 1;
                        .search-close-btn-wrapper {
                            display: none !important;
                        }
                        >.App-header-search-slidein-inner{height: 100%;
                        >.search-field-wrapper{
                            height: 100%;
                            >div.input-wrapper{
                                height: 100%;
                                background-color: $white;
                                >.MuiAutocomplete-root {
                                    flex-grow: 1;
                                    padding: 0 $gap-sm;
                                }
                                >input.header-search-input{
                                    border: none;
                                    padding: $gap-sm;
                                }
                                >button.search{
                                    padding: $gap-sm;
                                    border: none;
                                    background-color: rgba(0, 0, 0, .1);
                                    >svg{
                                        fill:$color-brand
                                    }
                                    >p{display: none;}
                                }
                            }
                        }}
                    }
                }

                #App-mobile-menu-body {
                    display: grid;
                    grid-template-columns: auto;
                    grid-template-rows: auto;
                    grid-template-areas: "main";
                    max-height: 100vh;
                    .App-mobile-menu-categories {
                        grid-area: main;
                    }
                    .App-mobile-menu-subcategories {
                        grid-area: main;
                    }
                    .App-mobile-menu-search {
                        grid-area: main;
                    }
                    .App-mobile-menu-filter {
                        grid-area: main;
                        .product-category-page-filter{
                            .product-category-page-filter-inner{
                                .collapse-group-wrapper{
                                    background-color: $white;
                                    .formOptionsGrid{
                                        display: flex;
                                        flex-direction: column;
                                    }
                                }
                            }
                        }
                    }
                    .App-mobile-menu-language {
                        >.app-language-selector{
                            margin-top: $gap;
                            >.MuiFormControl-root {
                                margin: 0 !important;

                                >.MuiFilledInput-root {
                                    background: none;
                                    border: none;

                                    >.MuiSelect-filled {
                                        padding-top: .5em;
                                        padding-bottom: .5em;
                                    }
                                }
                            }
                        }
                    }

                    .App-mobile-menu-list {
                        transition: transform .3s ease;
                        &.active {}

                        &.inactive {
                            transform: translateX(calc(-100% - $gap));
                        }


                        .App-mobile-menu-item {
                            display: flex;
                            align-items: center;
                            background-color: $white;
                            margin-bottom: $gap-sm;
                            padding-left: $gap-sm;
                            transition: background-color .3s ease;
                            &:hover {
                                background-color: $color-brand;
                                >button,
                                >a {
                                    color: $white;
                                }
                            }
                            >img {
                                width: 1.5em;
                                height: 1.5em;
                                object-fit: contain;
                                margin-right: $m1;
                                background: $white;
                                box-shadow: 0 0 0 .5em $white;
                            }

                            >button,
                            >a {
                                transition: color .3s ease;
                                flex-grow: 1;
                                border: none;
                                background-color: transparent;
                                text-decoration: none;
                                padding: $gap-sm;
                                color: $font-color;

                                >div {
                                    >span {
                                        margin-right: $gap-sm;
                                        font-size: 1.2em;
                                        color: $font-color;
                                    }

                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                }
                            }
                            .App-mobile-menu-search-count{
                                padding: $m1;
                                margin-right: $m1;
                                background-color: $color-brand;
                                color:$white;
                                box-shadow: 0 .2em .2em 0 rgba(0, 0, 0, .1),0 0 .1em 0 rgba(0, 0, 0, .1);
                            }
                        }
                    }
                }
            }
        }
    }

}
@media only screen and (max-width: $bp-xxl){
    header#App-header #App-header-main {
        .App-header-navigation .App-header-nav-list>.App-header-nav-item{
            font-size: 1.1rem
        }
        .App-header-search .panel .input-wrapper.search{
            width:10em
        }
    }
}
@media only screen and (max-width: $bp-xl){
    header#App-header #App-header-main .App-header-navigation .App-header-nav-list>.App-header-nav-item{
        font-size: 1rem
    }
}

@media only screen and (max-width: $bp-lg),
screen and (max-width: $bp-lg-retina) and (-webkit-min-device-pixel-ratio: 2) {
    header {
        &#App-header {
            position: fixed;
            top:0;
            left: 0;
            width: 100vw;
            z-index: 1000;
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: auto auto;
            grid-template-areas: "header" "mobileMenu";
            #App-utility-navigation {
                display: none !important;
            }

            #App-header-main {
                grid-area: header;
                grid-template-rows: 1fr;
                grid-template-columns: 50px 50px 50px 1fr 50px 50px 50px;
                grid-template-areas: "nav search filter logo . user cart";
                padding: 0 $gap-sm;
                grid-gap: 0;
                background-color: $white;
                align-items: center;
                box-shadow: 0 -1em 1em 1em rgba(0, 0, 0, 0.2);
                .App-header-logo{
                    padding: 0;
                    display: flex;
                    justify-content: center;
                    >a{
                        >img{max-height: 1.5em;}
                    }
                }
                >.App-header-search-slidein,
                .App-header-navigation {
                    display: none !important;
                }

                #App-mobile-menu-btn {
                    display: flex;
                }

                .App-header-search {
                    .panel {
                        .input-wrapper {
                            &.search {
                                width: auto;
                                border: none;
                
                                button {
                                    &.search-btn {
                                        padding: $gap-sm;
                
                                        >span {
                                            display: none;
                                        }
                
                                        &:focus {
                                            outline: none !important;
                                        }
                                    }
                
                                    svg {
                                        width:1.3em;
                                        margin-right: 0;
                                    }
                                }
                
                            }
                        }
                    }
                }
                .App-header-filter {
                    display: flex;
                    .panel {
                        .input-wrapper {
                            &.filter {
                                button {
                                    &.filter-btn {
                                        padding: $gap-sm;
                                    }
                                    svg {
                                        width: 1.3em;
                                        margin-right: 0;
                                    }
                                }
                            }
                        }
                    }
                }
                .App-header-user {
                    display: block;
                    >div {
                        button{
                            padding: 0;
                        }
                        >a{
                            padding: $gap-sm;
                        }
                        svg{
                            fill: $font-color;
                        }
                        .user-login-name {
                            display: none;
                        }
                        .user-logout-text {
                            display: none;
                        }
                        .user-login-text {
                            display: none;
                        }
                    }
                }
                .App-header-cart{
                    >div{
                        >#cart-btn{
                            >div{
                                >svg{
                                    width: 2rem;
                                }
                                >.cart-item-num-indicator{
                                    bottom:1.33rem;
                                    left: 1.33rem;
                                    height: 15px;
                                    min-width: 15px;
                                }
                            }
                        }
                    }
                }
            }

            #App-mobile-menu {
                grid-area: mobileMenu;
                display: block;
                >.App-mobile-menu-wrapper{

                    >#App-mobile-menu-header {
                        
                    }
                    >#App-mobile-menu-body {
                        .App-mobile-menu-language {
                            background-color: $white;
                            padding: $gap-sm;
                            margin-top: $gap;
                            >.app-language-selector{
                                margin: 0;
                                .MuiFilledInput-root::before{
                                    border: 0;
                                }
                            }
                        }
                    }
                }
            }

            
        }
    }
}

@media only screen and (max-width: $bp-sm) {
    header {
        &#App-header {
            #App-header-main {
                grid-template-columns: 1fr 1fr 1fr 2fr 1fr 1fr 1fr;
                padding: 0;
                .App-header-logo {
                    >a {
                        >img {
                            max-height: 1em;
                        }
                    }
                }
                >#App-mobile-menu-btn {
                    padding: $gap-sm $m1;
                }
                >.App-header-search >.panel >.input-wrapper.search >button.search-btn {
                    padding: $gap-sm $m1;
                }
                >.App-header-filter >.panel >.input-wrapper.filter >button.filter-btn {
                    padding: $gap-sm $m1;
                }
                >.App-header-user >.App-header-user-info >a {
                    padding: $gap-sm $m1;
                }

            }
        }
    }
}